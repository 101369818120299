import { useUser } from '@folklore/auth';
import { loadFacebook } from '@folklore/services';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIsCheckingAuth } from './AuthContext';


export const FacebookContext = React.createContext({
    appId: null,
    sdk: typeof window !== 'undefined' && typeof window.FB !== 'undefined' ? window.FB : null,
    token: null,
    setRequiresSdk: () => {},
});

export function useFacebookContext() {
    const context = useContext(FacebookContext);
    return context;
}

export function useFacebookSdk() {
    const { sdk = null, setRequiresSdk } = useFacebookContext();
    useEffect(() => {
        setRequiresSdk(true);
    }, [setRequiresSdk]);
    return sdk;
}

export function useFacebookToken() {
    const { token = null } = useFacebookContext();
    return token;
}

const propTypes = {
    appId: PropTypes.string,
    version: PropTypes.string,
    children: PropTypes.node.isRequired,
};

const defaultProps = {
    appId: null,
    version: 'v21.0',
};

export function FacebookContextProvider({ appId, locale, version, children }) {
    const isCheckingAuth = useIsCheckingAuth();
    const user = useUser();
    const [requiresSdk, setRequiresSdk] = useState(false);
    const [sdk, setSdk] = useState(
        typeof window !== 'undefined' && typeof window.FB !== 'undefined' ? window.FB : null,
    );
    const [token, setToken] = useState(null);
    useEffect(() => {
        if (sdk !== null || isCheckingAuth || (user !== null && !requiresSdk)) {
            return () => {};
        }

        let canceled = false;
        loadFacebook({
            locale,
            version,
            appId,
        }).then((newSdk) => {
            if (!canceled) {
                setSdk(newSdk);
            }
        });
        return () => {
            canceled = true;
        };
    }, [sdk, appId, locale, version, user, isCheckingAuth, requiresSdk]);

    useEffect(() => {
        if (sdk === null) {
            return () => {};
        }
        function onStatusChange({ authResponse }) {
            setToken(authResponse !== null ? authResponse.accessToken : null);
        }
        sdk.Event.subscribe('auth.statusChange', onStatusChange);
        return () => {
            sdk.Event.unsubscribe('auth.statusChange', onStatusChange);
        };
    }, [sdk]);

    const value = useMemo(
        () => ({
            appId,
            sdk,
            token,
            setRequiresSdk,
        }),
        [appId, sdk, token, setRequiresSdk],
    );

    return <FacebookContext.Provider value={value}>{children}</FacebookContext.Provider>;
}

FacebookContextProvider.propTypes = propTypes;
FacebookContextProvider.defaultProps = defaultProps;
