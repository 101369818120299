/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import { without } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';

import { useTouchScreen } from '../../contexts/DeviceContext';
import PillButton from '../buttons/PillButton';
import Summary from '../summaries/Summary';
import DocumentsRowBlock from './DocumentsRowBlock';

import styles from '../../styles/blocks/summary-row-block.module.css';

const propTypes = {
    url: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    summary: PropTypes.object,
    summaryComponent: PropTypes.elementType,
    summaryPosition: PropTypes.oneOf([null, 'center', 'inline', 'bottom']),
    summaryImagePosition: PropTypes.oneOf([null, 'inline', 'left', 'right']),
    background: AppPropTypes.background,
    presentation: PropTypes.string,
    loading: PropTypes.oneOf([null, 'lazy']),
    withBorderRadius: PropTypes.bool,
    withoutSummaryImage: PropTypes.bool,
    withoutSummarySponsor: PropTypes.bool,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
    summaryClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
    containerRef: AppPropTypes.ref,
};

const defaultProps = {
    url: null,
    summary: null,
    summaryComponent: Summary,
    summaryPosition: null,
    summaryImagePosition: null,
    background: null,
    presentation: null,
    loading: 'lazy',
    withBorderRadius: false,
    withoutSummaryImage: false,
    withoutSummarySponsor: false,
    className: null,
    innerClassName: null,
    summaryClassName: null,
    buttonClassName: null,

    containerRef: null,
};

function SummaryRowBlock({
    url,
    summary,
    summaryComponent: SummaryComponent,
    summaryPosition,
    summaryImagePosition,
    background,
    presentation,
    withBorderRadius,
    withoutSummaryImage,
    withoutSummarySponsor,
    className,
    innerClassName,
    summaryClassName,
    buttonClassName,
    containerRef,
    loading,
    ...props
}) {
    const { url: summaryUrl = null } = summary || {};
    const finalUrl = url || summaryUrl;
    const isTouchScreen = useTouchScreen();
    const summaryElement = (
        <SummaryComponent
            withLink
            link={finalUrl}
            {...summary}
            loading={loading}
            withoutImage={withoutSummaryImage}
            withoutSponsor={withoutSummarySponsor}
            placeholder={summary === null}
            className={classNames([styles.summary, summaryClassName])}
            innerClassName={styles.summaryInner}
            descriptionClassName={styles.summaryDescription}
            imageClassName={styles.summaryImage}
            imagePosition={summaryImagePosition || (summaryPosition === 'inline' ? null : 'left')}
        />
    );
    return (
        <DocumentsRowBlock
            ref={containerRef}
            loading={loading}
            withBorderRadius={withBorderRadius}
            className={classNames([
                styles.container,
                {
                    [styles.rowList]:
                        (isTouchScreen && presentation === null) || presentation === 'row',
                    [styles[summaryPosition]]: summaryPosition !== null,
                    [styles.withBackground]: background !== null,
                    [styles.withBorderRadius]: withBorderRadius,
                    [className]: className !== null,
                },
            ])}
            innerClassName={classNames([styles.inner, innerClassName])}
            listClassName={styles.list}
            backgroundClassName={styles.background}
            itemsClassName={styles.items}
            header={
                <div className={styles.header}>
                    {summaryPosition !== 'bottom' ? summaryElement : null}
                    {finalUrl !== null ? (
                        <PillButton
                            compact
                            bordered
                            href={`${finalUrl}?_ref=view_all`}
                            className={classNames([styles.button, buttonClassName])}
                        >
                            <FormattedMessage
                                defaultMessage="Voir tout"
                                description="Button label"
                            />
                        </PillButton>
                    ) : null}
                </div>
            }
            footer={summaryPosition === 'bottom' ? summaryElement : null}
            background={background}
            presentation={presentation || (isTouchScreen ? 'row' : 'grid')}
            {...props}
        />
    );
}

SummaryRowBlock.propTypes = propTypes;
SummaryRowBlock.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <SummaryRowBlock containerRef={ref} {...props} />);
