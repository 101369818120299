import { useQuery, keepPreviousData as previousPlaceholder } from '@tanstack/react-query';

import { useApi } from '../contexts/ApiContext';

export function prefetchMedia(queryClient, api, name) {
    return queryClient.prefetchQuery({
        queryKey: ['media', name],
        queryFn: ({ queryKey: [, nameParam], signal }) =>
            api.medias.findByName(nameParam, { signal }),
    });
}

export function fetchMedia(queryClient, api, name) {
    return queryClient.fetchQuery({
        queryKey: ['media', name],
        queryFn: ({ queryKey: [, nameParam], signal }) =>
            api.medias.findByName(nameParam, { signal }),
    });
}

export default function useMedia(name, opts = null) {
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const {
        data: media = null,
        isFetched = false,
        ...queryResult
    } = useQuery({
        queryKey: ['media', name],
        queryFn: ({ queryKey: [, nameParam], signal }) =>
            api.medias.findByName(nameParam, { signal }),
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : null,
        ...opts,
    });

    return {
        media,
        notFound: media === null && isFetched,
        isFetched,
        ...queryResult,
    };
}
