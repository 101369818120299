import Base from './Base';

class Auth extends Base {
    check(query = null, opts = null) {
        return this.getJSONWithSession('/check', query, opts);
    }

    sendToken(data, opts = null) {
        return this.postJSONWithSession('/send-token', data, opts);
    }

    providerLogin(provider, data, opts = null) {
        return this.postJSONWithSession(`/${provider}/login`, data, opts);
    }

    login(data, opts = null) {
        return this.postJSONWithSession('/login', data, opts);
    }

    logout(opts = null) {
        return this.getJSONWithSession('/logout', null, opts);
    }

    identify(data, opts = null) {
        return this.postJSONWithSession('/identify', data, opts);
    }
}

export default Auth;
