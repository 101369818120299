/* eslint-disable react/jsx-props-no-spreading */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';

import { useBrands } from '../../contexts/SiteContext';
import PillsMenu from '../menus/PillsMenu';

import styles from '../../styles/partials/index-filters.module.css';

const propTypes = {
    route: PropTypes.string.isRequired,
    popularRoute: PropTypes.string,
    sort: PropTypes.oneOf([null, 'popular']),
    brand: AppPropTypes.brand,
    withoutBrands: PropTypes.bool,
    withoutSort: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    popularRoute: null,
    sort: null,
    brand: null,
    withoutBrands: false,
    withoutSort: false,
    className: null,
};

function IndexFilters({ route, popularRoute, sort, brand, withoutBrands, withoutSort, className }) {
    const url = useUrlGenerator();

    const brands = useBrands();
    const { handle: brandHandle = null, slug: brandSlug = null } = brand || {};

    const brandFilters = useMemo(() => {
        if (brands.length === 0) {
            return null;
        }
        return (brands || []).reduce((brandsList, currentBrand) => {
            const { label = null, handle = null, slug } = currentBrand || {};

            let brandUrl = url(route, {
                brand: slug,
            });
            if (sort === 'popular') {
                brandUrl =
                    popularRoute !== null
                        ? url(popularRoute, {
                              brand: slug,
                          })
                        : `${url(route, {
                              brand: slug,
                          })}?sort=popular`;
            }
            return [
                ...brandsList,
                {
                    label,
                    handle,
                    href: brandUrl,
                    bordered: true,
                    branded: true,
                    active: brandHandle === handle,
                },
            ];
        }, []);
    }, [brands, brandHandle, sort]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.inner}>
                {!withoutSort ? (
                    <PillsMenu
                        // title={
                        //     <FormattedMessage defaultMessage="Trier par" description="Filter title" />
                        // }
                        items={[
                            {
                                id: 'recent',
                                href: url(route, {
                                    brand: brandSlug,
                                }),
                                label: (
                                    <FormattedMessage
                                        defaultMessage="Plus récents"
                                        description="Filter label"
                                    />
                                ),
                                bordered: true,
                                branded: true,
                                active: sort === null,
                            },
                            {
                                id: 'popular',
                                href:
                                    popularRoute !== null
                                        ? url(popularRoute, {
                                              brand: brandSlug,
                                          })
                                        : `${url(route, {
                                              brand: brandSlug,
                                          })}?sort=popular`,
                                label: (
                                    <FormattedMessage
                                        defaultMessage="Plus populaires"
                                        description="Filter label"
                                    />
                                ),
                                bordered: true,
                                branded: true,
                                active: sort === 'popular',
                            },
                        ]}
                    />
                ) : null}
                {brandFilters.length > 1 && !withoutBrands ? (
                    <PillsMenu
                        // title={
                        //     <FormattedMessage
                        //         defaultMessage="Filtrer par média"
                        //         description="Filter title"
                        //     />
                        // }
                        items={brandFilters}
                    />
                ) : null}
            </div>
        </div>
    );
}

IndexFilters.propTypes = propTypes;
IndexFilters.defaultProps = defaultProps;

export default IndexFilters;
