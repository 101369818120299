/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from '../../styles/typography/body.module.css';

const propTypes = {
    children: PropTypes.node,
    theme: PropTypes.oneOf(['quatre95', 'periodelibre', 'dehors', 'france', 'mollo', 'inherit']),
    withoutMaxWidth: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

const defaultProps = {
    children: null,
    theme: null,
    withoutMaxWidth: false,
    className: null,
    onClick: null,
};

function Body({ children, theme, withoutMaxWidth, className, onClick }) {
    const finalClassName = classNames([
        styles.container,
        {
            [styles.withoutMaxWidth]: withoutMaxWidth,
        },
        className,
    ]);
    return (
        <div className={finalClassName} data-theme={theme} onClick={onClick}>
            {children}
        </div>
    );
}

Body.propTypes = propTypes;
Body.defaultProps = defaultProps;

export default Body;
