/* eslint-disable react/jsx-no-useless-fragment */
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useParams } from 'wouter';

import useDocument from '../../hooks/useDocument';

const propTypes = {};

const defaultProps = {};

function PreloadMicromag() {
    const { slug: slugParam, path = null } = useParams();
    const { document = null } = useDocument(slugParam);
    if (document === null) {
        return null;
    }
    const { micromag = null } = document;
    const screenIndex = !isEmpty(path) ? parseInt(path, 10) - 1 : 0;
    const { medias, components } = micromag || {};
    const { video: { media = null } = {} } = (components || [])[screenIndex] || null;
    const {
        files: {
            h264: { url = null } = {},
        } = {},
    } = (media !== null ? medias[media] : null) || {};
    return (
        <>{url !== null ? <link rel="preload" type="video/mp4" href={url} as="video" /> : null}</>
    );
}

PreloadMicromag.propTypes = propTypes;
PreloadMicromag.defaultProps = defaultProps;

export default PreloadMicromag;
