/* eslint-disable react/jsx-props-no-spreading */
import { useIsVisible } from '@folklore/hooks';
import { useUrlGenerator } from '@folklore/routes';
import { useTracking } from '@folklore/tracking';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { usePopularDocuments } from '../../hooks/useDocuments';
import useElementSize from '../../hooks/useElementSize';
import useScrollEnter from '../../hooks/useScrollEnter';
import { usePageViewTracking } from '../../hooks/useTracking';
import useUniqueDocumentsBlocks from '../../hooks/useUniqueDocumentsBlocks';
import useWindowSize from '../../hooks/useWindowSize';
import * as AppPropTypes from '../../lib/PropTypes';
import {
    createDocumentsGrid,
    createHomeSectionGrid,
    createLayoutRow,
    createVideosRow,
} from '../../lib/blocks';
import getBlocksWithAddons from '../../lib/getBlocksWithAddons';

import Blocks from '../blocks/Blocks';
import BrandHeader from '../headers/BrandHeader';
// import CookiesModal from '../popups/CookiesModal';
// import SubscriptionModal from '../popups/SubscriptionModal';
import Bump from '../partials/Bump';
import RowTitle from '../partials/RowTitle';

import styles from '../../styles/pages/home-page-blocks.module.css';

const propTypes = {
    brand: AppPropTypes.brand,
    header: PropTypes.node,
    blocks: AppPropTypes.blocks,
    cardTheme: PropTypes.string,
    additionalBlocks: AppPropTypes.blocks,
    current: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    blocksClassName: PropTypes.string,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
};

const defaultProps = {
    brand: null,
    header: null,
    blocks: null,
    cardTheme: 'box-translucent',
    additionalBlocks: null,
    current: false,
    className: null,
    contentClassName: null,
    blocksClassName: null,
    onEnter: null,
    onLeave: null,
};

function HomePageBlocks({
    brand,
    header,
    blocks,
    cardTheme,
    additionalBlocks,
    current,
    onEnter,
    onLeave,
    className,
    contentClassName,
    blocksClassName,
}) {
    const url = useUrlGenerator();
    const {
        id: brandId = null,
        handle: brandHandle = null,
        slug: brandSlug = null,
        sections = null,
    } = brand || {};

    const finalBlocks = useMemo(
        () =>
            blocks || [
                {
                    role: 'block',
                    type: 'header_featured_list',
                    brand,
                    withBrandLogo: false,
                    cardTheme: 'over-bottom-left',
                    cardWithout: ['category'],
                    withBorderRadius: true,
                    count: 1,
                    query: {
                        brand: brandId,
                        exclude_type: 'contest',
                        placement: [`home_featured_${brandHandle}`],
                    },
                    uniqueDocuments: true,
                },
                createLayoutRow('1_1_1_4', {
                    cardTheme,
                    title: (
                        <RowTitle url={url('all', { brand: brandSlug })}>
                            <FormattedMessage
                                defaultMessage="Les plus récents"
                                description="Section title"
                            />
                        </RowTitle>
                    ),
                    query: {
                        brand: brandId,
                        placement: [`home_new_${brandHandle}`, `home_featured_${brandHandle}`],
                    },
                    clickRef: 'home_view',
                    loading: 'lazy',
                    cardImageLoading: 'lazy',
                    cardWithout: ['brand-icon'],
                    uniqueDocuments: true,
                }),

                createLayoutRow('1_1_1_4', {
                    cardTheme,
                    title: (
                        <RowTitle url={url('popular', { brand: brandSlug })}>
                            <FormattedMessage
                                defaultMessage="Les plus populaires"
                                description="Section title"
                            />
                        </RowTitle>
                    ),
                    query: {
                        brand: brandId,
                        placement: [`home_popular_${brandHandle}`, `home_featured_${brandHandle}`],
                    },
                    clickRef: 'home_popular',
                    hook: usePopularDocuments,
                    loading: 'lazy',
                    cardImageLoading: 'lazy',
                    cardWithout: ['brand-icon'],
                    uniqueDocuments: true,
                }),

                createVideosRow({
                    title: (
                        <RowTitle url={url('videos', { brand: brandSlug })}>
                            <FormattedMessage
                                defaultMessage="Vidéos récentes"
                                description="Section title"
                            />
                        </RowTitle>
                    ),
                    query: {
                        brand: brandId,
                    },
                    clickRef: 'home_videos',
                    loading: 'lazy',
                    cardImageLoading: 'lazy',
                    uniqueDocuments: true,
                }),
                ...(additionalBlocks || []),
                ...sections.map(({ id: sectionId, label, slug }) =>
                    createDocumentsGrid(12, {
                        title: (
                            <RowTitle
                                url={url('section', { brand: brandSlug, section: slug })}
                            >
                                {label}
                            </RowTitle>
                        ),
                        query: {
                            brand: brandId,
                            section: sectionId,
                        },
                        clickRef: `home_section_${slug}`,
                        loading: 'lazy',
                        cardImageLoading: 'lazy',
                        cardTheme,
                        cardWithout: ['category', 'brand-icon'],
                        uniqueDocuments: true,
                    }),
                ),
            ],
        [blocks, sections, brandId, brandSlug, url, additionalBlocks],
    );

    const uniqueBlocks = useUniqueDocumentsBlocks(finalBlocks);
    const blocksWithAddons = useMemo(
        () =>
            getBlocksWithAddons(uniqueBlocks, {
                addons: [
                    {
                        block: 'ad',
                        max: null,
                        interval: 1,
                        minHeight: 2,
                    },
                ],

                addonsProps: {
                    ad: ({ count = 0 }) => ({
                        slot: count === 0 ? 'fullwidth_native' : 'fullwidth',
                    }),
                },
                minSpaceBetween: 1,
            }),
        [uniqueBlocks],
    );

    const [bumperPlaying, setBumperPlaying] = useState(brandHandle !== null && current);
    const [bumperMounted, setBumperMounted] = useState(bumperPlaying);
    useEffect(() => {
        if (brandHandle !== null && current) {
            setBumperPlaying(true);
        }
    }, [brandHandle]);

    const onBumperEnded = useCallback(() => {
        setBumperPlaying(false);
    }, [setBumperPlaying]);

    const onBumperFail = useCallback(() => {
        setBumperMounted(false);
    }, [setBumperMounted]);

    useEffect(() => {
        let timeout = null;
        if (bumperMounted && !bumperPlaying) {
            timeout = setTimeout(() => {
                setBumperMounted(false);
            }, 500);
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [bumperMounted, bumperPlaying]);

    const { height: windowHeight = 0 } = useWindowSize();
    const { ref: containerRef, height: containerHeight = 0 } = useElementSize({
        disabled: !current,
    });

    useScrollEnter({
        leaveThreshold:
            containerHeight > 0 && windowHeight > 0 ? containerHeight - windowHeight : null,
        disabled: !current,
        onEnter,
        onLeave,
    });

    usePageViewTracking(current, {
        brand,
        pageType: 'home',
    });

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.bumperIsVisible]: bumperPlaying,
                },
                styles[brandHandle],
                className,
                contentClassName,
            ])}
            ref={containerRef}
            data-brand={brandHandle}
        >
            {bumperMounted ? (
                <Bump
                    brand={brand}
                    playing={bumperPlaying}
                    onEnded={onBumperEnded}
                    onFail={onBumperFail}
                    className={styles.bumper}
                />
            ) : null}
            {header || <BrandHeader {...brand} className={styles.header} />}

            <Blocks
                blocks={blocksWithAddons}
                className={classNames([styles.blocks, blocksClassName])}
                blockClassName={styles.block}
            />
        </div>
    );
}

HomePageBlocks.propTypes = propTypes;
HomePageBlocks.defaultProps = defaultProps;

export default HomePageBlocks;
