/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';
import { createHomeSectionGrid } from '../../lib/blocks';

import RowTitle from '../partials/RowTitle';
import HomePageBlocks from './HomePageBlocks';

import styles from '../../styles/pages/home-page-dehors.module.css';

const propTypes = {
    brand: AppPropTypes.brand.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};
function HomePageDehors({ brand, className, ...props }) {
    const url = useUrlGenerator();
    const blocks = useMemo(() => [
        createHomeSectionGrid({
            title: (
                <RowTitle url={url('topic', { brand: brand.slug, slug: 'chien' })}>
                    Dehors avec son chien
                </RowTitle>
            ),
            query: {
                brand: brand.id,
                topic: ['chien'],
            },
            loading: 'lazy',
            cardImageLoading: 'lazy',
            cardTheme: 'rounded',
            uniqueDocuments: true,
        }),
        createHomeSectionGrid({
            title: (
                <RowTitle url={url('category', { brand: brand.slug, slug: 'famille' })}>
                    Dehors avec sa famille
                </RowTitle>
            ),
            query: {
                brand: brand.id,
                category: ['famille'],
            },
            loading: 'lazy',
            cardImageLoading: 'lazy',
            cardTheme: 'rounded',
            uniqueDocuments: true,
        }),
    ]);
    return (
        <HomePageBlocks
            {...props}
            cardTheme="rounded"
            brand={brand}
            additionalBlocks={blocks}
            className={classNames([styles.container, className])}
            blocksClassName={styles.blocks}
        />
    );
}

HomePageDehors.propTypes = propTypes;
HomePageDehors.defaultProps = defaultProps;

export default HomePageDehors;
