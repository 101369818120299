import { useUrlGenerator } from '@folklore/routes';
import { number } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Time from '../components/partials/Time';

export function useCollectionSurtitle(collection) {
    const {
        type = null,
        surtitle = null,
        itemsCount: snippetItemsCount = null,
        childrenCount: snippetChildrenCount = null,
        issueNumber: snippetIssueNumber = null,
        metadata,
    } = collection || {};
    const {
        itemsCount = snippetItemsCount,
        childrenCount = snippetChildrenCount,
        issueNumber = snippetIssueNumber,
    } = metadata || {};
    const isPodcast = type !== null && type.indexOf('podcast') !== -1;
    const isSerie = type === 'serie';
    const hasChildren = childrenCount !== null && childrenCount > 0;
    const hasItems = itemsCount !== null && itemsCount > 0;
    let finalSurtitle = null;
    if (surtitle !== null) {
        finalSurtitle = surtitle;
    } else if (isPodcast && hasChildren) {
        finalSurtitle = (
            <FormattedMessage
                defaultMessage="Balado · {count, plural, one {{count, number} saison} other {{count, number} saisons}}"
                description="Collection surtitle"
                values={{
                    count: childrenCount,
                }}
            />
        );
    } else if (isPodcast && hasItems) {
        finalSurtitle = (
            <FormattedMessage
                defaultMessage="Balado · {count, plural, one {{count, number} épisode} other {{count, number} épisodes}}"
                description="Collection surtitle"
                values={{
                    count: itemsCount,
                }}
            />
        );
    } else if (isPodcast) {
        finalSurtitle = (
            <FormattedMessage defaultMessage="Balado" description="Collection surtitle" />
        );
    } else if (isSerie && hasChildren) {
        finalSurtitle = (
            <FormattedMessage
                defaultMessage="Série · {count, plural, one {{count, number} saison} other {{count, number} saisons}}"
                description="Collection surtitle"
                values={{
                    count: childrenCount,
                }}
            />
        );
    } else if (isSerie && hasItems) {
        finalSurtitle = (
            <FormattedMessage
                defaultMessage="Série · {count, plural, one {{count, number} épisode} other {{count, number} épisodes}}"
                description="Collection surtitle"
                values={{
                    count: itemsCount,
                }}
            />
        );
    } else if (isSerie) {
        finalSurtitle = (
            <FormattedMessage defaultMessage="Série" description="Collection surtitle" />
        );
    } else if (type === 'magazine') {
        finalSurtitle =
            issueNumber !== null ? (
                <FormattedMessage
                    defaultMessage="Magazine #{number}"
                    description="Collection surtitle"
                    values={{
                        number: issueNumber,
                    }}
                />
            ) : (
                <FormattedMessage defaultMessage="Magazine" description="Collection surtitle" />
            );
    } else {
        finalSurtitle = (
            <FormattedMessage defaultMessage="Collection" description="Collection surtitle" />
        );
    }
    return finalSurtitle;
}

export function useDocumentSurtitle(document, opts) {
    const {
        surtitle = null,
        metadata = null,
        collection: snippetCollection = null,
        categories = null,
        date: snippetDate = null,
        topic: snippetTopic = null,
        format: snippetFormat = null,
        season: snippetSeason = null,
        episode: snippetEpisode = null,
        micromagNumber: snippetMicromagNumber = null,
    } = document || {};
    const {
        collections: [collection = null] = [snippetCollection],
        topics: [topic = null] = [snippetTopic],
        formats: [format = null] = [snippetFormat],
        categories: [category = null] = categories || [],
        season = snippetSeason,
        episode = snippetEpisode,
        micromagNumber = snippetMicromagNumber,
        date = snippetDate,
    } = metadata || {};
    const {
        withCategory = false,
        withoutSerie = false,
        withoutCollection = false,
        maxCollectionLength = 30,
        serieFirst = false,
    } = opts || {};
    const url = useUrlGenerator();
    let link = null;
    let label = null;
    if (surtitle !== null) {
        label = surtitle;
    } else if (micromagNumber !== null) {
        label =
            date !== null ? (
                <FormattedMessage
                    defaultMessage="Micromag {number} - {date}"
                    description="Document surtitle"
                    values={{
                        number: micromagNumber,
                        date: <Time date={date} month="long" textOnly />,
                    }}
                />
            ) : (
                <FormattedMessage
                    defaultMessage="Micromag {number}"
                    description="Document surtitle"
                    values={{
                        number: micromagNumber,
                    }}
                />
            );
    } else if (collection !== null && (season !== null || episode !== null) && !withoutCollection) {
        const serie = collection.serie || collection;
        const isNumeric =
            (season !== null && episode !== null) ||
            (episode !== null && episode.match(/\d+/) !== null);
        const episodeLabel =
            season !== null && episode !== null ? (
                <FormattedMessage
                    defaultMessage="Saison {season} - Ép. {episode}"
                    description="Document surtitle"
                    values={{ season, episode }}
                />
            ) : (
                episode
            );
        if (withoutSerie) {
            label = episodeLabel;
        } else if (isNumeric) {
            label = (
                <>
                    <small>{episodeLabel}</small>
                    <br />
                    {serie.title}
                </>
            );
        } else {
            label = (
                <>
                    {serie.title}
                    {' · '}
                    {episodeLabel}
                </>
            );
        }
        link = url('collection', {
            slug: serie.slug,
        });
    } else if (
        collection !== null &&
        (maxCollectionLength === null || collection.title.length < maxCollectionLength) &&
        !withoutCollection
    ) {
        label = collection.title;
        link = url('collection', {
            slug: collection.slug,
        });
    } else if (topic !== null) {
        label = topic.label;
        link = url('topic', {
            slug: topic.slug,
        });
    } else if (format !== null) {
        label = format.label;
        link = url('format', {
            slug: format.slug,
        });
    } else if (withCategory && category !== null) {
        label = category.label;
        link = url('category', {
            slug: category.slug,
        });
    }

    if (label === null) {
        return null;
    }

    return {
        url: link,
        label,
    };
}
