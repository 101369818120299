/* eslint-disable react/jsx-props-no-spreading */
import { useIsVisible } from '@folklore/hooks';
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import useCollection from '../../hooks/useCollection';
import useScrollEnter from '../../hooks/useScrollEnter';
import { useCollectionSurtitle } from '../../hooks/useSurtitle';
import { useDocumentPageViewTracking } from '../../hooks/useTracking';

import { CollectionAdsTargetingProvider } from '../../contexts/AdsTargeting';
import Blocks from '../blocks/Blocks';
import CollectionDefaultHeader from '../headers/CollectionDefaultHeader';
import InfiniteItemsBlocks from '../lists/InfiniteItemsBlocks';
import SectionTitle from '../typography/SectionTitle';
import NotFoundPage from './NotFoundPage';

import styles from '../../styles/pages/collection-page.module.css';

const propTypes = {
    slug: PropTypes.string.isRequired,
    current: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
};

const defaultProps = {
    current: false,
    className: null,
    contentClassName: null,
    onEnter: null,
    onLeave: null,
};

function CollectionPage({ slug, current, className, contentClassName, onEnter, onLeave }) {
    const intl = useIntl();
    const url = useUrlGenerator();
    const { collection = null, notFound = false } = useCollection(slug);
    const {
        type = null,
        id: collectionId = null,
        surtitle,
        title,
        description,
        header = null,
        metadata,
        image,
        seasons = null,
        children = null,
    } = collection || {};
    const { brands, sponsors } = metadata || {};

    const finalSurtitle = useCollectionSurtitle({ type, surtitle, metadata });

    const { ref: endRef, visible: endVisible = false } = useIsVisible({
        disabled: !current,
        // rootMargin: `100px 0px`,
    });

    useScrollEnter({
        leave: endVisible,
        disabled: !current,
        onEnter,
        onLeave,
    });

    const isPodcast = type === 'serie_podcast' || type === 'season_podcast';
    const hasSeasons = seasons !== null && seasons.length > 0;
    const hasChildren = children !== null && children.length > 0;

    useDocumentPageViewTracking(current && collection !== null, collection);

    const childrenBlocks = useMemo(() => {
        if (hasSeasons) {
            return seasons.map(({ id: seasonId, title: seasonTitle }) => ({
                type: 'documents-row',
                presentation: 'grid',
                title: <SectionTitle>{seasonTitle}</SectionTitle>,

                cardWithout: ['brand-icon', 'serie', 'collection', 'category'],
                query: {
                    collection: seasonId,
                },
                loading: 'lazy',

                count: null,
                placeholderCount: 4,
                ...(isPodcast
                    ? {
                          cardType: 'horizontal',
                          cardTheme: 'box-translucent-dark',
                          cardRatio: 'square',
                          columns: 1,
                      }
                    : {
                          cardType: 'over',
                          cardSize: 'big',
                          cardTheme: 'outline',
                          cardRatio: 'vertical',
                          cardWidth: 300,
                      }),
            }));
        }
        return (children || []).map(({ id: childId, slug: childSlug, ...child }) => ({
            type: 'collection-row',
            presentation: 'grid',
            slug: childSlug,

            cardWithout: ['brand-icon', 'serie', 'collection', 'category'],
            withoutSummarySponsor: true,
            summaryImagePosition: 'left',
            // query: {
            //     collection: childId,
            // },
            loading: 'lazy',
        }));
    }, [hasSeasons, seasons, children]);

    if (notFound) {
        return <NotFoundPage current={current} />;
    }

    return (
        <CollectionAdsTargetingProvider collection={collection}>
            <div className={classNames([styles.container, className, contentClassName])}>
                <CollectionDefaultHeader
                    type={type}
                    title={title}
                    surtitle={finalSurtitle}
                    description={description}
                    brands={brands}
                    sponsors={sponsors}
                    background={
                        type === 'magazine'
                            ? {
                                  color: '#fff',
                              }
                            : null
                    }
                    {...header}
                    image={header !== null ? header.image || image : image}
                    placeholder={collection === null}
                    className={styles.header}
                />
                <main className={styles.content}>
                    {childrenBlocks !== null && childrenBlocks.length > 0 ? (
                        <Blocks
                            blocks={childrenBlocks}
                            className={styles.blocks}
                            blockClassName={classNames([
                                styles.row,
                                {
                                    [styles.season]: hasSeasons,
                                    [styles.podcast]: isPodcast,
                                },
                            ])}
                        />
                    ) : null}
                    {!hasChildren ? (
                        <InfiniteItemsBlocks
                            query={
                                hasSeasons && isPodcast
                                    ? {
                                          collection: collectionId,
                                          exclude_type: 'podcast',
                                      }
                                    : {
                                          collection: collectionId,
                                      }
                            }
                            disabled={collectionId === null}
                            className={styles.blocks}
                            cardTheme="box-translucent-dark"
                            cardWithout={['brand-icon', 'serie', 'collection']}
                        />
                    ) : null}
                </main>
                <div
                    ref={endRef}
                    style={{
                        height: 1,
                    }}
                />
            </div>
        </CollectionAdsTargetingProvider>
    );
}

CollectionPage.propTypes = propTypes;
CollectionPage.defaultProps = defaultProps;

export default CollectionPage;
