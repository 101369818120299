/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'wouter';

import * as AppPropTypes from '../../lib/PropTypes';
import getRatio from '../../lib/getRatio';
import camelCase from 'lodash/camelCase';

import CollectionImage from '../partials/CollectionImage';
import PlaceholderText from '../partials/PlaceholderText';
import Sponsor from '../partials/Sponsor';
import CardTitle from '../typography/CardTitle';
import HorizontalCard from './HorizontalCard';

import styles from '../../styles/cards/collection-horizontal-card.module.css';

const propTypes = {
    slug: PropTypes.string,
    title: PropTypes.string,
    image: AppPropTypes.image,
    brands: AppPropTypes.brands,
    sponsors: AppPropTypes.sponsors,
    theme: PropTypes.oneOf([null, 'menu']),
    size: AppPropTypes.cardSize,
    ratio: PropTypes.string,
    height: PropTypes.number,
    placeholder: PropTypes.bool,
    withBorder: PropTypes.bool,
    withoutBrandIcon: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    slug: null,
    title: null,
    image: null,
    sponsors: null,
    theme: null,
    size: null,
    brands: null,
    ratio: null,
    height: null,
    placeholder: false,
    withBorder: false,
    withoutBrandIcon: false,
    className: null,
};

function CollectionHorizontalCard({
    slug,
    title,
    image,
    brands,
    sponsors,
    theme,
    ratio,
    size,
    height,
    placeholder,
    withBorder,
    withoutBrandIcon,
    className,
}) {
    const url = useUrlGenerator();
    const [brand = null] = brands || [];
    const { handle: brandHandle } = brand || {};
    const thumbnailRatio = getRatio(ratio, 5 / 4);
    const [sponsor = null] = sponsors || [];

    const isBig = size === 'big';
    const isSmall = size === 'small';
    const isTiny = size === 'tiny';

    let defaultHeight = 100;
    if (isBig) {
        defaultHeight = 150;
    } else if (isSmall) {
        defaultHeight = 70;
    } else if (isTiny) {
        defaultHeight = 50;
    }

    const finalHeight= height || defaultHeight;

    return (
        <HorizontalCard
            href={
                slug !== null
                    ? url('collection', {
                          slug,
                      })
                    : null
            }
            thumbnail={
                <CollectionImage
                    brand={brandHandle}
                    width={thumbnailRatio * finalHeight}
                    height={finalHeight}
                    media={image}
                    withPlaceholder={placeholder}
                    withoutBrandIcon={withoutBrandIcon}
                    className={styles.thumbnail}
                />
            }
            thumbnailRatio={thumbnailRatio}
            height={finalHeight}
            labelClassName={styles.label}
            className={classNames([
                styles.container,
                {
                    [styles.withBorder]: withBorder,
                },
                styles[camelCase(size)],
                styles[camelCase(theme)],
                className,
            ])}
        >
            <CardTitle tag="span" className={styles.title}>
                {placeholder ? <PlaceholderText /> : title}
            </CardTitle>

            {sponsor !== null ? <Sponsor {...sponsor} iconOnly className={styles.sponsor} /> : null}
        </HorizontalCard>
    );
}

CollectionHorizontalCard.propTypes = propTypes;
CollectionHorizontalCard.defaultProps = defaultProps;

export default CollectionHorizontalCard;
