/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import PlaceholderText from '../partials/PlaceholderText';
import CardTitle from '../typography/CardTitle';
import HorizontalCard from './HorizontalCard';

import styles from '../../styles/cards/location-type-horizontal-card.module.css';

const MapIcon = React.lazy(() => import('../icons/maps/MapIcon'));

const propTypes = {
    slug: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.string,
    theme: PropTypes.oneOf([null, 'pill', 'without_thumbnail']),
    size: AppPropTypes.cardSize,
    icon: PropTypes.string,
    active: PropTypes.bool,
    placeholder: PropTypes.bool,
    withBorder: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    slug: null,
    onClick: null,
    label: null,
    theme: null,
    size: null,
    icon: null,
    active: false,
    placeholder: false,
    withBorder: false,
    className: null,
};

function LocationTypeHorizontalCard({
    slug,
    onClick,
    label,
    theme,
    size,
    icon,
    active,
    placeholder,
    withBorder,
    className,
}) {
    const url = useUrlGenerator();

    return (
        <HorizontalCard
            href={
                slug !== null && onClick === null
                    ? url('map.locationType', {
                          locationType: slug,
                      })
                    : null
            }
            onClick={() => {
                if (onClick !== null) {
                    onClick(slug);
                }
            }}
            thumbnail={
                <Suspense fallback={<span className={styles.icon} />}>
                    <MapIcon icon={icon || slug || 'autres-sports'} className={styles.icon} />
                </Suspense>
            }
            className={classNames([
                styles.container,
                styles[camelCase(size)],
                styles[camelCase(theme)],
                {
                    [styles.withBorder]: withBorder,
                    [styles.active]: active,
                },
                className,
            ])}
            innerClassName={styles.inner}
            thumbnailClassName={styles.thumbnailContainer}
        >
            <CardTitle tag="span" className={styles.title}>
                {placeholder ? <PlaceholderText lines={1} /> : label}
            </CardTitle>
        </HorizontalCard>
    );
}

LocationTypeHorizontalCard.propTypes = propTypes;
LocationTypeHorizontalCard.defaultProps = defaultProps;

export default LocationTypeHorizontalCard;
