import Base from './Base';

class Medias extends Base {
    findByName(name = null, opts = null) {
        return this.getJSON(`/medias/${name}`, null, opts).catch(e => {
            if (e.status === 404) {
                return null;
            }
            throw e;
        });
    }
}

export default Medias;
