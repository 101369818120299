/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable import/prefer-default-export */
import { AuthContext, useAuthContext } from '@folklore/auth';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { useAuthUser } from '../hooks/useAuth';

export function useIsCheckingAuth() {
    const { isLoading } = useAuthContext();
    return isLoading;
}

export function AuthContextProvider({ disabled, children }) {
    const { user = null, isLoading = false } = useAuthUser({
        enabled: !disabled,
    });

    const value = useMemo(
        () => ({
            user,
            isLoading,
        }),
        [user, isLoading],
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthContextProvider.propTypes = {
    disabled: PropTypes.bool,
    children: PropTypes.node.isRequired,
};
AuthContextProvider.defaultProps = {
    disabled: false,
};
