import React from 'react';

export default {
    span: (...chunks) => <span>{chunks}</span>,
    small: (...chunks) => <small>{chunks}</small>,
    strong: (...chunks) => <strong>{chunks}</strong>,
    b: (...chunks) => <strong>{chunks}</strong>,
    em: (...chunks) => <em>{chunks}</em>,
    p: (...chunks) => <p>{chunks}</p>,
    br: <br />
};
