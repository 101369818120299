import { useUser } from '@folklore/auth';
import { TrackingContainer } from '@folklore/tracking';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';

import { useAddAudiences } from '../hooks/useVisitor';
import Tracking from '../lib/Tracking';

import { useIsCheckingAuth } from './AuthContext';
import { useSite } from './SiteContext';
import { useVisitorAudiences, useVisitorId } from './VisitorContext';
import { useConsent, useConsentGiven } from './ConsentContext';

const propTypes = {
    children: PropTypes.node.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    variables: PropTypes.object,
};

const defaultProps = {
    variables: null,
};

export function TrackingProvider({ children, variables }) {
    const user = useUser();
    const hasConsent = useConsent('analytics_storage');
    const visitorId = useVisitorId();
    const visitorAudiences = useVisitorAudiences();
    // const consentNeeded = useConsentNeeded();
    const consentGiven = useConsentGiven();
    const isCheckingAuth = useIsCheckingAuth();
    const { audiences = [] } = useSite();
    const paused = isCheckingAuth || !hasConsent;
    const { addAudiences } = useAddAudiences();
    const tracking = useMemo(
        () =>
            new Tracking({
                user,
                paused,
            }),
        [],
    );
    useEffect(() => {
        if (variables !== null) {
            tracking.setVariables(variables);
        }
    }, [variables]);
    useEffect(() => {
        if (!isCheckingAuth) {
            tracking.setUser(user);
        }
    }, [user, isCheckingAuth]);

    useEffect(() => {
        tracking.setVariables({
            consentGiven,
        });
    }, [consentGiven]);

    useEffect(() => {
        if (visitorId !== null || visitorAudiences !== null) {
            tracking.setVariables({
                visitorId,
                audiences: visitorAudiences,
            });
        }
    }, [visitorId, visitorAudiences]);

    useEffect(() => {
        tracking.setPaused(paused);
    }, [paused]);

    useEffect(() => {
        function onAudienceTrigger({ handle }) {
            addAudiences(handle);
        }

        const audiencesWithTrigger =
            audiences !== null ? audiences.filter(({ trigger = null }) => trigger !== null) : [];
        audiencesWithTrigger.forEach((audience) => {
            const { handle, trigger } = audience;
            tracking.triggers.register(`audience_${handle}`, trigger, () =>
                onAudienceTrigger(audience),
            );
        });

        return () => {
            audiencesWithTrigger.forEach(({ handle }) => {
                tracking.triggers.unregister(`audience_${handle}`);
            });
        };
    }, [tracking, audiences, addAudiences]);

    return <TrackingContainer tracking={tracking}>{children}</TrackingContainer>;
}

TrackingProvider.propTypes = propTypes;
TrackingProvider.defaultProps = defaultProps;

export default TrackingProvider;
