/* eslint-disable react/jsx-props-no-spreading */
import { useUrlGenerator } from '@folklore/routes';
import { useTracking } from '@folklore/tracking';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRoute, useSearch } from 'wouter';

import useFiltersQuery from '../../hooks/useFiltersQuery';
import useScrollEnter from '../../hooks/useScrollEnter';
import { usePageViewTracking } from '../../hooks/useTracking';

import { useBrand, useDefaultBrand } from '../../contexts/SiteContext';
import IndexDefaultHeader from '../headers/IndexDefaultHeader';
import InfiniteItemsBlocks from '../lists/InfiniteItemsBlocks';
import IndexFilters from '../partials/IndexFilters';

import styles from '../../styles/pages/all-page.module.css';

const propTypes = {
    brandSlug: PropTypes.string,
    sort: PropTypes.string,
    current: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
};

const defaultProps = {
    brandSlug: null,
    sort: null,
    current: false,
    className: null,
    contentClassName: null,
    onEnter: null,
    onLeave: null,
};

function AllPage({ brandSlug, sort, current, className, contentClassName, onEnter, onLeave }) {
    const filters = useFiltersQuery({
        sort,
    });
    const hasBrand = brandSlug !== null;
    const { handle: defaultBrandHandle = 'urbania' } = useDefaultBrand();
    const brand = useBrand(brandSlug, !hasBrand ? defaultBrandHandle : null);
    const { handle: brandHandle = null } = brand || {};

    const search = useSearch();
    const { sort: sortQuery = sort } = useMemo(() => queryString.parse(search), [search]);
    const finalSort = sortQuery || sort;

    useScrollEnter({
        disabled: !current,
        onEnter,
        onLeave,
    });

    usePageViewTracking(current, {
        brand,
        pageType: 'index',
    });

    return (
        <div
            className={classNames([styles.container, className, contentClassName])}
            data-brand={brandHandle}
        >
            <IndexDefaultHeader
                title={
                    finalSort === 'popular' ? (
                        <FormattedMessage
                            defaultMessage="Les plus populaires"
                            description="Page title"
                        />
                    ) : (
                        <FormattedMessage
                            defaultMessage="Les plus récents"
                            description="Page title"
                        />
                    )
                }
                className={styles.header}
            />
            <IndexFilters
                route="all"
                popularRoute="popular"
                sort={finalSort}
                brand={brand}
                className={styles.filters}
            />
            <main className={styles.content}>
                <InfiniteItemsBlocks
                    query={{
                        brand: brand.id,
                        ...filters,
                    }}
                    cardWithout={['brand-icon', 'type-icon']}
                />
            </main>
        </div>
    );
}

AllPage.propTypes = propTypes;
AllPage.defaultProps = defaultProps;

export default AllPage;
