import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

import * as AppPropTypes from '../lib/PropTypes';

export const LayoutContext = React.createContext({
    menuOpened: false,
    menuOverContent: false,
    contentBreakpoint: null,
    contentWidth: null,
    openMenu: () => {},
    closeMenu: () => {},
});

export function useLayoutContext() {
    const context = useContext(LayoutContext);
    return context;
}

export function useMenu() {
    const {
        menuOpened: opened,
        openMenu: open,
        closeMenu: close,
        menuOverContent: overContent,
    } = useLayoutContext();
    return {
        opened,
        overContent,
        open,
        close,
    };
}

export function useGlobalBrand() {
    const { brand = null } = useLayoutContext();
    return brand;
}

const propTypes = {
    menuOpened: PropTypes.bool,
    openMenu: PropTypes.func,
    closeMenu: PropTypes.func,
    menuOverContent: PropTypes.bool,
    brand: AppPropTypes.brand,
    children: PropTypes.node,
};

const defaultProps = {
    menuOpened: false,
    openMenu: () => {},
    closeMenu: () => {},
    menuOverContent: false,
    brand: null,
    children: null,
};

export function LayoutContextProvider({
    menuOpened,
    openMenu,
    closeMenu,
    menuOverContent,
    brand,
    children,
}) {
    const value = useMemo(
        () => ({
            menuOpened,
            openMenu,
            closeMenu,
            menuOverContent,
            brand,
        }),
        [menuOpened, menuOverContent, openMenu, closeMenu, brand],
    );

    return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
}

LayoutContextProvider.propTypes = propTypes;
LayoutContextProvider.defaultProps = defaultProps;
