/* eslint-disable react/jsx-props-no-spreading */
import { useIsVisible } from '@folklore/hooks';
import { useUrlGenerator } from '@folklore/routes';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import useAuthor from '../../hooks/useAuthor';

import AuthorSummary from '../summaries/AuthorSummary';
import SummaryRowBlock from './SummaryRowBlock';

const propTypes = {
    slug: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    query: PropTypes.object,
    loading: PropTypes.oneOf([null, 'lazy']),
    withoutSubscribeButton: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    query: null,
    loading: 'lazy',
    withoutSubscribeButton: false,
    className: null,
};

function AuthorRowBlock({ slug, query, loading, withoutSubscribeButton, className, ...props }) {
    const url = useUrlGenerator();
    const { ref, visible: isVisible } = useIsVisible({
        persist: true,
        disabled: loading !== 'lazy',
    });
    const { author = null } = useAuthor(slug, {
        enabled: loading !== 'lazy' || isVisible,
    });
    const finalQuery = useMemo(
        () =>
            author !== null
                ? {
                      author: author.id,
                      ...query,
                  }
                : null,
        [author, query],
    );

    const summary = useMemo(
        () =>
            author !== null
                ? {
                      ...author,
                      withSubscribeButton: !withoutSubscribeButton,
                      withLink: withoutSubscribeButton,
                  }
                : null,
        [author, withoutSubscribeButton],
    );

    return (
        <SummaryRowBlock
            url={url('author', { slug })}
            summary={summary}
            summaryComponent={AuthorSummary}
            query={finalQuery}
            disabled={author === null}
            loading={loading}
            ref={ref}
            className={className}
            summaryImagePosition="left"
            {...props}
        />
    );
}

AuthorRowBlock.propTypes = propTypes;
AuthorRowBlock.defaultProps = defaultProps;

export default AuthorRowBlock;
