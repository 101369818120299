/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React from 'react';

// import { FormattedMessage } from 'react-intl';
// import useFontSize from '../../hooks/useFontSize';
import * as AppPropTypes from '../../lib/PropTypes';

import BrandLogoNormalized from '../icons/BrandLogoNormalized';
import DocumentsList from '../lists/DocumentsList';
import BrandSummary from '../summaries/BrandSummary';

import styles from '../../styles/cards/brand-documents-card.module.css';

const propTypes = {
    id: PropTypes.string,
    slug: PropTypes.string,
    handle: PropTypes.string,
    shortDescription: PropTypes.string,
    theme: PropTypes.oneOf([null, 'menu']),
    size: AppPropTypes.cardSize,
    ratio: PropTypes.oneOf(['square', 'square_double']),
    listTitle: PropTypes.node,
    // eslint-disable-next-line react/forbid-prop-types
    listProps: PropTypes.object,
    listCount: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    listQuery: PropTypes.object,
    placeholder: PropTypes.bool,
    withoutText: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    id: null,
    slug: null,
    handle: null,
    shortDescription: null,
    theme: null,
    size: null,
    ratio: null,
    listTitle: null,
    listProps: null,
    listCount: 3,
    listQuery: null,
    placeholder: false,
    withoutText: false,
    className: null,
};

function BrandDocumentsCard({
    id,
    slug,
    handle,
    shortDescription,
    theme,
    size,
    ratio,
    listTitle,
    listProps,
    listCount,
    listQuery,
    placeholder,
    withoutText,
    className,
}) {
    const url = useUrlGenerator();
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.withoutText]: withoutText,
                },
                styles[camelCase(handle)],
                styles[camelCase(size)],
                styles[camelCase(theme)],
                styles[camelCase(ratio)],
                className,
            ])}
            data-brand={handle}
        >
            <BrandSummary
                slug={slug}
                // label={label}
                description={shortDescription}
                image={
                    handle !== null ? (
                        <BrandLogoNormalized brand={handle} className={styles.logo} />
                    ) : null
                }
                imagePosition="top"
                className={styles.summary}
                imageClassName={styles.summaryImage}
                pictureClassName={styles.summaryPicture}
                titleClassName={styles.summaryTitle}
                descriptionClassName={styles.summaryDescription}
                withoutTitle
                placeholder={placeholder}
                withLink
                link={
                    slug !== null
                        ? url('home', {
                              brand: slug,
                          })
                        : '#'
                }
            />
            {listTitle !== null ? <div className={styles.title}>{listTitle}</div> : null}
            <DocumentsList
                query={
                    !placeholder
                        ? {
                              brand: id,
                              ...listQuery,
                          }
                        : null
                }
                disabled={placeholder}
                cardType="horizontal"
                cardRatio={ratio}
                cardSize={size}
                cardTheme={theme}
                count={listCount}
                columns={1}
                placeholder={placeholder}
                className={styles.list}
                {...listProps}
            />
        </div>
    );
}

BrandDocumentsCard.propTypes = propTypes;
BrandDocumentsCard.defaultProps = defaultProps;

export default BrandDocumentsCard;
