import { startTransition, useEffect } from 'react';

import { isInInputElement, isInInteractiveElement } from '../lib/isInElement';

import { useHasModals } from '../contexts/PopupsContext';

function useScrollEnter({
    leave = false,
    disabled = false,
    enterThreshold = 20,
    leaveThreshold = null,
    onEnter = null,
    onLeave = null,
    disableNavigation = false,
} = {}) {
    const hasModals = useHasModals();
    useEffect(() => {
        if (disabled) {
            return () => {};
        }
        let lastScrollY = 0;

        function forceLeave() {
            const focusedElement = document.activeElement || null;
            const interactiveHasFocus = focusedElement !== null && isInInputElement(focusedElement);
            const leaveDisabled = interactiveHasFocus || hasModals;
            if (leave && !leaveDisabled && onLeave !== null) {
                startTransition(() => {
                    onLeave(disableNavigation);
                });
                return true;
            }
            return false;
        }

        function onScroll() {
            const currentScrollY = window.scrollY;
            const direction = currentScrollY < lastScrollY ? -1 : 1;
            lastScrollY = currentScrollY;
            const focusedElement = document.activeElement || null;
            const interactiveHasFocus = focusedElement !== null && isInInputElement(focusedElement);
            const leaveDisabled = interactiveHasFocus || hasModals;
            if (forceLeave()) {
                return;
            }

            if (
                leaveThreshold !== null &&
                currentScrollY > leaveThreshold &&
                !leaveDisabled &&
                onLeave !== null
            ) {
                startTransition(() => {
                    onLeave(disableNavigation);
                });
            } else if (currentScrollY > enterThreshold && onEnter !== null) {
                startTransition(() => {
                    onEnter(disableNavigation);
                });
            } else if (
                currentScrollY <= enterThreshold &&
                !leaveDisabled &&
                direction === -1 &&
                onLeave !== null
            ) {
                startTransition(() => {
                    onLeave(disableNavigation);
                });
            }
        }
        window.addEventListener('scroll', onScroll);
        forceLeave();

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [
        disabled,
        leave,
        onEnter,
        onLeave,
        enterThreshold,
        leaveThreshold,
        disableNavigation,
        hasModals,
    ]);
}

export default useScrollEnter;
