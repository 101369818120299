/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React from 'react';

import { useDocumentSurtitle } from '../../hooks/useSurtitle';
import { useDocumentUrlGenerator } from '../../hooks/useUrlGenerator';
// import { FormattedMessage } from 'react-intl';
// import useFontSize from '../../hooks/useFontSize';
import * as AppPropTypes from '../../lib/PropTypes';
import getRatio from '../../lib/getRatio';

import Author from '../partials/Author';
import Category from '../partials/Category';
import DocumentImage from '../partials/DocumentImage';
import Duration from '../partials/Duration';
import Sponsor from '../partials/Sponsor';
import Surtitle from '../partials/Surtitle';
import Time from '../partials/Time';
import CardTitle from '../typography/CardTitle';
// import SponsorContent from '../typography/SponsorContent';
import VerticalCard from './VerticalCard';

import styles from '../../styles/cards/document-vertical-card.module.css';

const propTypes = {
    type: PropTypes.string,
    slug: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    linkQuery: PropTypes.object,
    title: PropTypes.string,
    surtitle: PropTypes.string,
    sponsors: AppPropTypes.sponsors,
    brands: AppPropTypes.taxonomies,
    image: AppPropTypes.image,
    coverImage: AppPropTypes.image,
    categories: AppPropTypes.taxonomies,
    credits: AppPropTypes.credits,
    duration: PropTypes.number,
    topic: AppPropTypes.taxonomy,
    format: AppPropTypes.taxonomy,
    collection: AppPropTypes.collection,
    date: PropTypes.string,
    season: PropTypes.string,
    episode: PropTypes.string,
    micromagNumber: PropTypes.string,
    ratio: PropTypes.oneOf([
        'horizontal',
        'micromag',
        'square',
        'vertical',
        'square_double',
        'vertical_double',
    ]),
    theme: PropTypes.oneOf([
        null,
        'menu',
        'rounded',
        'label-on-top',
        'suggestion',
        'box',
        'box-brand',
        'box-inverted',
        'box-dark',
    ]),
    size: PropTypes.oneOf([null, 'small', 'big', 'responsive']),
    width: PropTypes.number,
    imageLoading: AppPropTypes.loading,
    placeholder: PropTypes.bool,
    responsive: PropTypes.bool,
    withBorder: PropTypes.bool,
    withCategory: PropTypes.bool,
    withoutSurtitle: PropTypes.bool,
    withoutAuthor: PropTypes.bool,
    withoutAuthorImage: PropTypes.bool,
    withoutDuration: PropTypes.bool,
    withoutTime: PropTypes.bool,
    withoutBrandIcon: PropTypes.bool,
    withoutTypeIcon: PropTypes.bool,
    withoutSerie: PropTypes.bool,
    withoutCollection: PropTypes.bool,
    className: PropTypes.string,
    thumbnailClassName: PropTypes.string,
    onClick: PropTypes.func,
};

const defaultProps = {
    type: null,
    brands: null,
    slug: null,
    linkQuery: null,
    title: null,
    surtitle: null,
    image: null,
    coverImage: null,
    categories: null,
    credits: null,
    sponsors: null,
    topic: null,
    format: null,
    collection: null,
    duration: null,
    date: null,
    season: null,
    episode: null,
    micromagNumber: null,
    theme: null,
    size: null,
    ratio: null,
    width: null,
    placeholder: false,
    responsive: false,
    imageLoading: undefined,
    withBorder: false,
    withCategory: false,
    withoutSurtitle: false,
    withoutAuthor: false,
    withoutAuthorImage: false,
    withoutDuration: false,
    withoutTime: false,
    withoutBrandIcon: false,
    withoutTypeIcon: false,
    withoutSerie: false,
    withoutCollection: false,
    className: null,
    thumbnailClassName: null,
    onClick: null,
};

function DocumentVerticalCard({
    type,
    slug,
    linkQuery,
    title,
    surtitle,
    brands,
    image,
    coverImage,
    categories,
    credits,
    sponsors,
    topic,
    format,
    collection,
    duration,
    date,
    season,
    episode,
    micromagNumber,
    theme,
    size,
    ratio,
    width,
    placeholder,
    responsive,
    imageLoading,
    withBorder,
    withCategory,
    withoutSurtitle,
    withoutAuthor,
    withoutAuthorImage,
    withoutDuration,
    withoutTime,
    withoutBrandIcon,
    withoutTypeIcon,
    withoutSerie,
    withoutCollection,
    className,
    thumbnailClassName,
    onClick,
}) {
    const url = useDocumentUrlGenerator();
    const [category = null] = categories || [];
    const [{ author = null } = {}] = credits || [];
    const [brand = null] = brands || [];
    const { handle: brandHandle } = brand || {};
    const [sponsor = null] = sponsors || [];
    // const fontSize = useFontSize(title, 240, 80);

    const finalSurtitle = useDocumentSurtitle(
        {
            surtitle,
            collection,
            topic,
            format,
            season,
            episode,
            categories,
            micromagNumber,
        },
        {
            withoutSerie,
            withoutCollection,
            withCategory: !withCategory,
        },
    );

    const isMenu = theme === 'menu';
    const isTiny = size === 'tiny';
    const isBox = theme !== null && theme.indexOf('box') === 0;
    const isMicromag = type === 'micromag';
    const isSuggestion = theme === 'suggestion';
    const labelOnTop = theme === 'label-on-top';

    const defaultRatio = 5 / 4;
    // let defaultRatio = 1;
    // if (type === 'article') {
    //     defaultRatio = 5 / 4;
    // } else if (type === 'video') {
    //     defaultRatio = 4 / 5;
    // } else if (type === 'micromag') {
    //     defaultRatio = 4 / 6;
    // }

    const thumbnailRatio = getRatio(ratio, defaultRatio);

    const hasDuration = duration !== null && duration > 0;
    const showDuration = type === 'video' || type === 'podcast';
    const showCategory = withCategory && !isSuggestion && !isMicromag && !isTiny;
    const showSurtitle = !withoutSurtitle && !isSuggestion && !isTiny;

    const meta = (
        <div className={styles.meta}>
            {(placeholder || (author !== null && sponsor === null)) && !withoutAuthor ? (
                <Author
                    {...author}
                    placeholder={placeholder}
                    className={styles.author}
                    withoutImage={withoutAuthorImage}
                />
            ) : null}

            {sponsor !== null ? (
                <Sponsor
                    {...sponsor}
                    textOnly
                    className={styles.sponsor}
                    labelClassName={styles.sponsorLabel}
                />
            ) : null}

            {date !== null && !withoutTime && (size !== 'small' || true) ? (
                <Time date={date} className={styles.time} />
            ) : null}
        </div>
    );

    return (
        <VerticalCard
            href={
                url(
                    {
                        type,
                        slug,
                    },
                    linkQuery,
                ) || '#'
            }
            onClick={!placeholder ? onClick : null}
            thumbnail={
                <>
                    <DocumentImage
                        type={type}
                        withIcon={!placeholder}
                        withoutTypeIcon={withoutTypeIcon}
                        withoutBrandIcon={withoutBrandIcon}
                        brand={brandHandle}
                        size="medium"
                        width={width}
                        height={width !== null ? width / thumbnailRatio : null}
                        autoSize={width === null}
                        loading={imageLoading}
                        withPlaceholder
                        media={
                            type === 'micromag' && (ratio === 'vertical' || ratio === 'micromag')
                                ? coverImage || image
                                : image
                        }
                        className={styles.thumbnail}
                        iconClassName={styles.thumbnailIcon}
                        withoutSize
                    />

                    {hasDuration && showDuration && !withoutDuration ? (
                        <Duration
                            duration={duration}
                            timestamp={type === 'video'}
                            className={styles.duration}
                        />
                    ) : null}
                </>
            }
            // height={isBox ? 'auto' : null}
            thumbnailRatio={thumbnailRatio}
            className={classNames([
                styles.container,
                {
                    [styles.withBorder]: withBorder && !isBox,
                    [styles.hasSponsor]: sponsor !== null,
                    [styles.hasSurtitle]: finalSurtitle !== null && showSurtitle,
                    [styles.box]: isBox,
                },
                styles[camelCase(theme)],
                styles[camelCase(size)],
                styles[camelCase(brandHandle)],
                styles[camelCase(ratio)],
                {
                    [styles.responsive]: responsive,
                },
                className,
            ])}
            thumbnailClassName={classNames([styles.thumbnailContainer, thumbnailClassName])}
            innerClassName={styles.inner}
            labelClassName={classNames([
                styles.label,
                {
                    [styles.withTime]: date !== null && !withoutTime && size === 'small',
                },
            ])}
            labelOnTop={labelOnTop}
            footer={labelOnTop ? meta : null}
        >
            {(category !== null || placeholder) && showCategory ? (
                <Category {...category} placeholder={placeholder} className={styles.category} />
            ) : null}
            {finalSurtitle !== null && showSurtitle ? (
                <Surtitle surtitle={finalSurtitle} className={styles.surtitle} />
            ) : null}
            <CardTitle className={styles.title} placeholder={placeholder} placeholderLines={3}>
                {title}
            </CardTitle>
            {!labelOnTop && !isSuggestion ? meta : null}
        </VerticalCard>
    );
}

DocumentVerticalCard.propTypes = propTypes;
DocumentVerticalCard.defaultProps = defaultProps;

export default DocumentVerticalCard;
