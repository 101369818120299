/* eslint-disable no-param-reassign */

/* eslint-disable react/jsx-props-no-spreading */
import { useForm } from '@folklore/forms';
import { useTracking } from '@folklore/tracking';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAuthIdentify } from '../../hooks/useAuth';
import * as AppPropTypes from '../../lib/PropTypes';

import { useApi } from '../../contexts/ApiContext';
import FormButton from '../buttons/FormButton';
import SsoButton from '../buttons/SsoButton';
import ContactField from '../fields/ContactField';
import FormStatus from '../partials/FormStatus';
import LineSpacer from '../partials/LineSpacer';
import FormControl from './FormControl';

import styles from '../../styles/forms/identify-form.module.css';

const propTypes = {
    action: PropTypes.string.isRequired,
    source: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.node,
    className: PropTypes.string,
    formRef: AppPropTypes.ref,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onComplete: PropTypes.func,
    onChange: PropTypes.func,
    onClickSso: PropTypes.func,
};

const defaultProps = {
    placeholder: null,
    source: 'identify',
    label: null,
    className: null,
    formRef: null,
    onFocus: null,
    onBlur: null,
    onComplete: null,
    onChange: null,
    onClickSso: null,
};

function IdentifyForm({
    placeholder,
    source,
    label,
    className,
    formRef,
    onFocus,
    onBlur,
    onComplete,
    onChange,
    onClickSso: customOnClickSso,
}) {
    const intl = useIntl();
    const api = useApi();
    const { identifyAsync } = useAuthIdentify();
    const formFields = useMemo(() => ['field', 'phone', 'email'].filter(Boolean), []);
    const tracking = useTracking();

    const [fieldType, setFieldType] = useState('email');
    const method = fieldType === 'tel' ? 'phone' : 'email';

    const postSendToken = useCallback(
        (formAction, { field }) =>
            identifyAsync({
                source,
                [method]: field,
            }),
        [method, identifyAsync, source],
    );
    const onFormComplete = useCallback(
        (newUser) => {
            if (onComplete !== null) {
                onComplete(newUser);
            }
            tracking.trackEvent('Auth', 'identify', method);
        },
        [onComplete, tracking, method],
    );
    const onSsoComplete = useCallback(
        (provider, newUser) => {
            if (onComplete !== null) {
                onComplete(newUser);
            }
            tracking.trackEvent('Auth', 'identify', provider);
        },
        [onComplete, tracking],
    );

    const onClickSso = useCallback(
        (provider) => {
            if (customOnClickSso !== null) {
                customOnClickSso(provider);
            }
            tracking.trackEvent('Auth', 'click_sso', provider);
        },
        [customOnClickSso, tracking],
    );

    const { submit, value, fields, onSubmit, status, setValue, errors, setErrors } = useForm({
        fields: formFields,
        postForm: postSendToken,
        onComplete: onFormComplete,
    });

    const { errors: fieldErrors = null } = fields[method] || {};
    const onFieldTypeChange = useCallback(
        (newType) => {
            setFieldType(newType);
            setErrors(null);
        },
        [setFieldType],
    );

    const inputRef = useRef(null);

    useEffect(() => {
        if (errors !== null) {
            // inputRef.current.focus();
        }
    }, [errors]);

    useEffect(() => {
        if (onChange !== null) {
            onChange(value);
        }
    }, [value]);

    return (
        <form
            className={classNames([
                styles.container,
                {
                    'was-validated': errors !== null,
                    [className]: className !== null,
                },
            ])}
            onSubmit={onSubmit}
            ref={(ref) => {
                if (formRef !== null) {
                    formRef.current = ref;
                    if ((ref || null) !== null) {
                        formRef.current.submit = submit;
                    }
                }
            }}
        >
            <FormControl
                className={styles.formControl}
                label={label}
                {...fields[method]}
                withoutErrors
            >
                <div
                    className={classNames([
                        styles.fieldGroup,
                        {
                            [styles.hasStatus]: status !== null,
                        },
                    ])}
                >
                    <ContactField
                        {...fields.field}
                        ref={inputRef}
                        required
                        errors={fieldErrors}
                        className={styles.field}
                        placeholder={
                            placeholder ||
                            intl.formatMessage({
                                defaultMessage: 'Inscrivez votre # de tel ou courriel',
                                description: 'Field placeholder',
                            })
                        }
                        onTypeChange={onFieldTypeChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                    <FormStatus status={status} className={styles.status} />
                </div>
            </FormControl>

            <FormButton type="submit" disabled={status === 'loading'} className={styles.button}>
                <FormattedMessage
                    defaultMessage="Continuer"
                    description="Button label"
                />
            </FormButton>
            <LineSpacer className={styles.spacer}>
                <FormattedMessage defaultMessage="ou" description="Spacer label" />
            </LineSpacer>
            <div className={styles.sso}>
                {['facebook', 'google'].map((provider) => (
                    <SsoButton
                        key={`sso-${provider}`}
                        provider={provider}
                        source={source}
                        // size="medium"
                        className={styles.ssoButton}
                        onClick={() => onClickSso(provider)}
                        onComplete={(newUser) => onSsoComplete(provider, newUser)}
                    />
                ))}
            </div>
        </form>
    );
}

IdentifyForm.propTypes = propTypes;
IdentifyForm.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <IdentifyForm {...props} formRef={ref} />);
