import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { useApi } from '../contexts/ApiContext';

export function useVisitor(opts = null) {
    const api = useApi();
    const { data = null, ...queryResult } = useQuery({
        queryKey: ['visitor'],
        queryFn: ({ signal }) => api.visitor.get({ signal }),
        suspense: false,
        staleTime: Infinity,
        ...opts,
    });

    return {
        visitorId: null,
        audiences: null,
        isNew: false,
        ...data,
        ...queryResult,
    };
}

export function useAddAudiences() {
    const api = useApi();
    const queryClient = useQueryClient();
    const { mutate, mutateAsync, ...mutationResult } = useMutation({
        mutationFn: (audiences) => api.visitor.addAudiences(audiences),
        onSuccess: (newAudiences) => {
            queryClient.setQueryData(['visitor'], {
                ...queryClient.getQueryData(['visitor']),
                audiences: newAudiences,
            });
        },
    });

    return {
        addAudiences: mutate,
        addAudiencesSync: mutateAsync,
        ...mutationResult,
    };
}
