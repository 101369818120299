/* eslint-disable no-nested-ternary, react/jsx-props-no-spreading, react/no-array-index-key */
import { getComponentFromName } from '@folklore/utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';

import * as InteractiveComponents from '../../interactives';

import styles from '../../styles/documents/interactive-document.module.css';

const propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    component: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    component: null,
    className: null,
};

function InteractiveDocument({ component, className, ...props }) {
    const InteractiveComponent = getComponentFromName(InteractiveComponents, component);
    return (
        <div className={classNames([styles.container, className])}>
            <Suspense fallback={<div className={styles.loading} />}>
                {InteractiveComponent !== null ? <InteractiveComponent {...props} /> : null}
            </Suspense>
        </div>
    );
}

InteractiveDocument.propTypes = propTypes;
InteractiveDocument.defaultProps = defaultProps;

export default InteractiveDocument;
