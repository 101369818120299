import { useQuery, useMutation, useQueryClient, useIsFetching } from '@tanstack/react-query';
import pick from 'lodash/pick';
import queryString from 'query-string';

import { useApi } from '../contexts/ApiContext';
import { useUpdateConsent } from '../contexts/ConsentContext';
import { useFacebookContext } from '../contexts/FacebookContext';

// import { updateConsent } from '../contexts/ConsentContext';

function getAuthQuery() {
    return pick(
        queryString.parse(typeof window !== 'undefined' ? window.location.search : '') || {},
        ['_cio_id', '_cio_did'],
    );
}

function updateVisitor(
    queryClient,
    { visitorId: newVisitorId = null, audiences: newAudiences = null },
) {
    if (newVisitorId !== null || newAudiences !== null) {
        const {
            audiences = null,
            visitorId = null,
            ...other
        } = queryClient.getQueryData(['visitor']) || {};
        queryClient.setQueryData(['visitor'], {
            ...other,
            audiences: newAudiences || audiences,
            visitorId: newVisitorId || visitorId,
        });
    }
}

export function prefetchAuthUser(queryClient, api) {
    return queryClient.prefetchQuery({
        queryKey: ['user'],
        queryFn: ({ signal }) =>
            api.auth
                .check(getAuthQuery(), { signal })
                .then(({ user = null, audiences, visitorId }) => {
                    updateVisitor(queryClient, {
                        audiences,
                        visitorId,
                    });
                    return user;
                }),
    });
}

export function useAuthUser(opts = null) {
    const api = useApi();
    const queryClient = useQueryClient();
    const { data = null, ...queryResult } = useQuery({
        queryKey: ['user'],
        queryFn: ({ signal }) =>
            api.auth
                .check(getAuthQuery(), { signal })
                .then(({ user = null, audiences, visitorId }) => {
                    updateVisitor(queryClient, {
                        audiences,
                        visitorId,
                    });
                    // if (user !== null) {
                    //     updateConsent(true);
                    // }
                    return user;
                }),
        suspense: false,
        ...opts,
    });

    return {
        user: data,
        ...queryResult,
    };
}

export function useAuthSendToken() {
    const api = useApi();
    const { mutate, mutateAsync, ...mutationResult } = useMutation({
        mutationFn: (data) => api.auth.sendToken(data),
    });

    return {
        sendToken: mutate,
        sendTokenAsync: mutateAsync,
        ...mutationResult,
    };
}

export function useAuthLogin() {
    const api = useApi();
    const queryClient = useQueryClient();
    const { mutate, mutateAsync, ...mutationResult } = useMutation({
        mutationFn: (data) => api.auth.login(data),
        onSuccess: ({ user, audiences, visitorId }) => {
            queryClient.setQueryData(['user'], user);
            updateVisitor(queryClient, { audiences, visitorId });
        },
    });

    return {
        login: mutate,
        loginAsync: mutateAsync,
        ...mutationResult,
    };
}

export function useAuthProviderLogin(provider) {
    const api = useApi();
    const queryClient = useQueryClient();
    const { mutate, mutateAsync, ...mutationResult } = useMutation({
        mutationFn: (data) => api.auth.providerLogin(provider, data),
        onSuccess: ({ user, audiences, visitorId }) => {
            queryClient.setQueryData(['user'], user);
            updateVisitor(queryClient, { audiences, visitorId });
        },
    });

    return {
        login: mutate,
        loginAsync: mutateAsync,
        ...mutationResult,
    };
}

export function useAuthIdentify() {
    const api = useApi();
    const queryClient = useQueryClient();
    const updateConsent = useUpdateConsent();
    const { mutate, mutateAsync, ...mutationResult } = useMutation({
        mutationFn: (data) => api.auth.identify(data),
        onSuccess: ({ user, audiences, visitorId }) => {
            queryClient.setQueryData(['user'], user);
            if (user !== null) {
                updateConsent(true);
            }
            updateVisitor(queryClient, { audiences, visitorId });
        },
    });

    return {
        identify: mutate,
        identifyAsync: mutateAsync,
        ...mutationResult,
    };
}

export function useAuthLogout() {
    const api = useApi();
    const queryClient = useQueryClient();
    const { sdk: facebookSdk = null, token: facebookToken = null } = useFacebookContext();
    const { mutate, mutateAsync, ...mutationResult } = useMutation({
        mutationFn: () => api.auth.logout(),
        onSuccess: () => {
            queryClient.setQueryData(['user'], null);
            if (facebookToken !== null && facebookSdk !== null) {
                facebookSdk.logout();
            }
        },
    });

    return {
        logout: mutate,
        logoutAsync: mutateAsync,
        ...mutationResult,
    };
}
