/* eslint-disable react/jsx-props-no-spreading */
import { useUser } from '@folklore/auth';
import { useForm } from '@folklore/forms';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useSubscription } from '../../hooks/useSubscription';
import * as AppPropTypes from '../../lib/PropTypes';

import { useSubscriptions } from '../../contexts/SiteContext';
import FormButton from '../buttons/FormButton';
import SubscriptionHorizontalCard from '../cards/SubscriptionHorizontalCard';
import ContactField from '../fields/ContactField';
import UserField from '../fields/UserField';
import LoadingIcon from '../icons/LoadingIcon';
import FormStatus from '../partials/FormStatus';
import EditContactModal from '../popups/EditContactModal';
import FormControl from './FormControl';

import styles from '../../styles/forms/subscription-form.module.css';

const propTypes = {
    subscription: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    source: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.node,
    full: PropTypes.bool,
    buttonOnly: PropTypes.bool,
    subscribeButtonLabel: PropTypes.bool,
    compact: PropTypes.bool,
    alwaysHorizontal: PropTypes.bool,
    withoutClose: PropTypes.bool,
    withoutCancel: PropTypes.bool,
    withoutButtons: PropTypes.bool,
    withoutSubscriptions: PropTypes.bool,
    withoutLogout: PropTypes.bool,
    withoutMessages: PropTypes.bool,
    fieldRef: AppPropTypes.ref,
    inputRef: AppPropTypes.ref,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onComplete: PropTypes.func,
    onCancel: PropTypes.func,
    onFieldPointerUp: PropTypes.func,
    className: PropTypes.string,
    fieldsClassName: PropTypes.string,
    buttonsClassName: PropTypes.string,
    subscriptionsClassName: PropTypes.string,
};

const defaultProps = {
    placeholder: null,
    source: null,
    label: null,
    full: false,
    buttonOnly: false,
    subscribeButtonLabel: null,
    compact: false,
    alwaysHorizontal: false,
    withoutClose: false,
    withoutCancel: false,
    withoutButtons: false,
    withoutSubscriptions: false,
    withoutLogout: false,
    withoutMessages: false,
    fieldRef: null,
    inputRef: null,
    onFocus: null,
    onBlur: null,
    onComplete: null,
    onCancel: null,
    onFieldPointerUp: null,
    className: null,
    fieldsClassName: null,
    buttonsClassName: null,
    subscriptionsClassName: null,
};

function SubscriptionForm({
    subscription: subscriptionHandle,
    source,
    placeholder,
    label,
    full,
    buttonOnly,
    subscribeButtonLabel,
    compact,
    alwaysHorizontal,
    withoutClose,
    withoutCancel,
    withoutButtons,
    withoutSubscriptions,
    withoutLogout,
    withoutMessages,
    fieldRef,
    inputRef,
    onFocus,
    onBlur,
    onComplete,
    onCancel,
    onFieldPointerUp,
    className,
    fieldsClassName,
    buttonsClassName,
    subscriptionsClassName,
}) {
    const allSubscriptions = useSubscriptions();
    const {
        subscribe,
        subscribed,
        subscription,
        editContactNeeded,
        closeEditContact,
        onEditContactComplete,
    } = useSubscription({
        subscription: subscriptionHandle,
        source,
        withoutMessages,
    });
    const { via = null } = subscription || {};
    const onlyVia = via !== null && via.length === 1 ? via[0] : null;
    const formFields = useMemo(() => ['field', 'phone', 'email'].filter(Boolean), []);

    const [focused, setFocused] = useState(false);
    const [fieldType, setFieldType] = useState(onlyVia === 'sms' ? 'tel' : 'email');
    const onFieldTypeChange = useCallback((newType) => setFieldType(newType), [setFieldType]);

    const onFieldFocus = useCallback(() => {
        setFocused(true);
    }, [setFocused]);

    const [subscriptions, setSubscriptions] = useState(
        subscriptionHandle !== null ? [subscriptionHandle] : [],
    );
    const user = useUser();
    const { subscriptions: userSubscriptions = [] } = user || {};

    const postSendToken = useCallback(
        (formAction, { field }) =>
            subscribe(
                full
                    ? {
                          subscriptions,
                          source,
                          [fieldType === 'tel' ? 'phone' : 'email']: field,
                      }
                    : {
                          [fieldType === 'tel' ? 'phone' : 'email']: field,
                      },
            ),
        [fieldType, subscriptions],
    );
    const onFormComplete = useCallback(
        (response) => {
            if (onComplete !== null) {
                onComplete(response, fieldType === 'tel' ? 'phone' : 'email');
            }
        },
        [onComplete, fieldType],
    );

    const { fields, onSubmit, status, setValue } = useForm({
        fields: formFields,
        postForm: postSendToken,
        onComplete: onFormComplete,
    });

    const { errors } = fields[fieldType === 'tel' ? 'phone' : 'email'] || {};

    const availableSubscriptions = useMemo(
        () =>
            allSubscriptions
                .filter(
                    ({ handle, type }) => type !== 'notification' || handle === subscriptionHandle,
                )
                .sort(({ handle: aHandle }, { handle: bHandle }) => {
                    if (aHandle === subscriptionHandle) {
                        return -1;
                    }
                    if (bHandle === subscriptionHandle) {
                        return 1;
                    }
                    return 0;
                }),
        [allSubscriptions, subscriptionHandle],
    );

    const onSubscriptionChange = useCallback(
        ({ handle }, checked) => {
            setSubscriptions(
                checked ? [...subscriptions, handle] : subscriptions.filter((s) => s !== handle),
            );
        },
        [setSubscriptions, subscriptions],
    );

    const onClickCancel = useCallback(() => {
        setValue(null);
        if (onCancel !== null) {
            onCancel();
        }
    }, [setValue, onCancel]);

    return (
        <form
            className={classNames([
                styles.container,
                {
                    'was-validated': errors !== null,
                    [styles.subscribed]: subscribed,
                    [styles.loading]: status === 'loading',
                    [styles.full]: full,
                    [styles.buttonOnly]: buttonOnly && !focused,
                    [styles.hasUser]: user !== null,
                    [styles.alwaysHorizontal]: alwaysHorizontal,
                    [className]: className !== null,
                },
            ])}
            onSubmit={onSubmit}
        >
            <FormControl
                className={classNames([styles.fields, fieldsClassName])}
                label={label}
                {...fields[fieldType === 'tel' ? 'phone' : 'email']}
            >
                <div
                    className={styles.fieldGroup}
                    ref={fieldRef}
                    data-theme={focused ? 'normal' : null}
                >
                    {user !== null ? (
                        <UserField
                            className={styles.field}
                            inputClassName={styles.input}
                            withBorder
                            withoutLogout={withoutLogout}
                        />
                    ) : (
                        <ContactField
                            {...fields.field}
                            type={onlyVia === 'sms' ? 'tel' : onlyVia}
                            ref={inputRef}
                            errors={errors}
                            className={styles.field}
                            inputClassName={styles.input}
                            placeholder={placeholder}
                            onTypeChange={onFieldTypeChange}
                            onFocus={onFieldFocus}
                            onPointerUp={onFieldPointerUp}
                            onBlur={onBlur}
                        />
                    )}
                    <LoadingIcon className={styles.loadingIcon} />
                    <FormButton
                        type="submit"
                        compact={compact && !focused}
                        className={styles.primaryButton}
                    >
                        {subscribeButtonLabel || (
                            <FormattedMessage
                                defaultMessage="S'abonner"
                                description="Button label"
                            />
                        )}
                    </FormButton>
                </div>
            </FormControl>
            {!withoutSubscriptions ? (
                <div className={classNames([styles.subscriptions, subscriptionsClassName])}>
                    {availableSubscriptions.map((subscriptionItem) => (
                        <SubscriptionHorizontalCard
                            {...subscriptionItem}
                            thumbnailWidth={50}
                            source={source !== null ? `${source}:suggestion` : 'suggestion'}
                            checked={subscriptions.indexOf(subscriptionItem.handle) !== -1}
                            withCheckbox={!subscribed}
                            withToggle={subscribed}
                            className={styles.subscription}
                            onChange={(checked) => onSubscriptionChange(subscriptionItem, checked)}
                        />
                    ))}
                </div>
            ) : null}
            {!withoutButtons ? (
                <div className={classNames([styles.buttons, buttonsClassName])}>
                    {!subscribed && !withoutCancel ? (
                        <FormButton
                            transparent
                            className={styles.secondaryButton}
                            onClick={onClickCancel}
                        >
                            <FormattedMessage defaultMessage="Annuler" description="Button label" />
                        </FormButton>
                    ) : null}
                    <FormStatus
                        status={status}
                        successMessage="Merci!"
                        iconRight
                        className={styles.status}
                    />
                    {!subscribed ? (
                        <FormButton
                            type="submit"
                            className={styles.primaryButton}
                            disabled={status === 'loading'}
                        >
                            {subscribeButtonLabel || (
                                <FormattedMessage
                                    defaultMessage="S'abonner"
                                    description="Button label"
                                />
                            )}
                        </FormButton>
                    ) : null}

                    {subscribed && !withoutClose ? (
                        <FormButton
                            type="button"
                            className={styles.primaryButton}
                            onClick={onClickCancel}
                        >
                            <FormattedMessage defaultMessage="Fermer" description="Button label" />
                        </FormButton>
                    ) : null}
                </div>
            ) : null}

            {editContactNeeded !== null ? (
                <EditContactModal
                    type={editContactNeeded}
                    title={
                        editContactNeeded === 'phone' ? (
                            <FormattedMessage
                                defaultMessage="Cet abonnement demande un numéro de téléphone"
                                description="Modal title"
                            />
                        ) : (
                            <FormattedMessage
                                defaultMessage="Cet abonnement demande un courriel"
                                description="Modal title"
                            />
                        )
                    }
                    onComplete={onEditContactComplete}
                    onClosed={closeEditContact}
                />
            ) : null}
        </form>
    );
}

SubscriptionForm.propTypes = propTypes;
SubscriptionForm.defaultProps = defaultProps;

export default SubscriptionForm;
