import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import parseISO from 'date-fns/parseISO';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link } from 'wouter';

import * as AppPropTypes from '../../lib/PropTypes';

import PillButton from '../buttons/PillButton';
import Date from './Date';

import styles from '../../styles/partials/document-metadata.module.css';

const propTypes = {
    categories: AppPropTypes.taxonomies,
    topics: AppPropTypes.taxonomies,
    formats: AppPropTypes.taxonomies,
    publishedAt: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    categories: null,
    topics: null,
    formats: null,
    publishedAt: null,
    className: null,
};

function DocumentMetadata({ categories, topics, formats, publishedAt, className }) {
    const url = useUrlGenerator();
    const items = useMemo(
        () => [
            ...(categories || []).map(({ slug, label }) => ({
                label,
                url: url('category', { slug }),
            })),
            ...(formats || []).map(({ slug, label }) => ({
                label,
                url: url('format', { slug }),
            })),
            ...(topics || []).map(({ slug, label }) => ({
                label,
                url: url('topic', { slug }),
            })),
        ],
        [categories, topics, formats],
    );
    const publishedAtObj = useMemo(
        () => (publishedAt !== null ? parseISO(publishedAt) : null),
        [publishedAt],
    );
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            {publishedAtObj !== null ? (
                <div className={styles.date}>
                    <FormattedMessage
                        defaultMessage="<strong>Publié le</strong> {date}"
                        values={{
                            date: (
                                <FormattedDate
                                    day="numeric"
                                    // weekday="short"
                                    month="long"
                                    year="numeric"
                                    value={publishedAtObj}
                                />
                            ),
                        }}
                    />
                </div>
            ) : null}
            {items.length > 0 ? (
                <div className={styles.items}>
                    {items.map(({ url: itemUrl, label }) => (
                        <PillButton href={itemUrl} compact bordered className={styles.button}>
                            {label}
                        </PillButton>
                    ))}
                </div>
            ) : null}
        </div>
    );
}

DocumentMetadata.propTypes = propTypes;
DocumentMetadata.defaultProps = defaultProps;

export default DocumentMetadata;
