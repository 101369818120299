/* eslint-disable react/jsx-props-no-spreading */
import { useUrlGenerator } from '@folklore/routes';
import { useTracking } from '@folklore/tracking';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import useAuthor from '../../hooks/useAuthor';
import useFiltersQuery from '../../hooks/useFiltersQuery';
import useScrollEnter from '../../hooks/useScrollEnter';
import { usePageViewTracking } from '../../hooks/useTracking';
import { useAuthorUrlGenerator } from '../../hooks/useUrlGenerator';

import AuthorDefaultHeader from '../headers/AuthorDefaultHeader';
import InfiniteItemsBlocks from '../lists/InfiniteItemsBlocks';
import SortMenu from '../menus/SortMenu';
import NotFoundPage from './NotFoundPage';

import styles from '../../styles/pages/author-page.module.css';

const propTypes = {
    slug: PropTypes.string.isRequired,
    current: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
};

const defaultProps = {
    current: false,
    className: null,
    contentClassName: null,
    onEnter: null,
    onLeave: null,
};

function AuthorPage({ slug, current, className, contentClassName, onEnter, onLeave }) {
    const url = useAuthorUrlGenerator();
    const { author = null, notFound = false } = useAuthor(slug, {
        suspense: false,
    });
    const { id: authorId = null } = author || {};
    const filters = useFiltersQuery();

    useScrollEnter({
        disabled: !current,
        onEnter,
        onLeave,
    });

    usePageViewTracking(current && author !== null, {
        authors: [author],
        pageType: 'author',
    });

    if (notFound) {
        return <NotFoundPage current={current} />;
    }

    return (
        <div className={classNames([styles.container, className, contentClassName])}>
            <AuthorDefaultHeader
                {...author}
                placeholder={author === null}
                className={styles.header}
            />
            <SortMenu
                url={
                    url({
                        slug,
                    }) || '#'
                }
                className={styles.filters}
            />
            <main className={styles.content}>
                <InfiniteItemsBlocks
                    query={{
                        author: authorId,
                        ...filters,
                    }}
                    cardTheme="box-translucent-dark"
                    disabled={authorId === null}
                />
            </main>
        </div>
    );
}

AuthorPage.propTypes = propTypes;
AuthorPage.defaultProps = defaultProps;

export default AuthorPage;
