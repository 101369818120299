import Base from './Base';

class Deliveries extends Base {
    findById(id = null, opts = null) {
        return this.getJSON(`/deliveries/${id}`, null, opts).catch(e => {
            if (e.status === 404) {
                return null;
            }
            throw e;
        });
    }
}

export default Deliveries;
