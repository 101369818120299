/* eslint-disable jsx-a11y/label-has-associated-control */

/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'wouter';

import * as AppPropTypes from '../../lib/PropTypes';

import BasicButton from '../buttons/BasicButton';

// import * as AppPropTypes from '../../lib/PropTypes';
import styles from '../../styles/cards/hybrid-card.module.css';

const propTypes = {
    href: PropTypes.string,
    thumbnail: PropTypes.node,
    thumbnailRatio: PropTypes.number,
    children: PropTypes.node,
    inner: PropTypes.node,
    brand: PropTypes.string,
    isLabel: PropTypes.bool,
    labelFor: PropTypes.string,
    containerRef: AppPropTypes.ref,
    className: PropTypes.string,
    thumbnailClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    onClick: PropTypes.func,
};

const defaultProps = {
    href: null,
    thumbnail: null,
    thumbnailRatio: null,
    children: null,
    inner: null,
    brand: null,
    isLabel: false,
    labelFor: null,
    containerRef: null,
    className: null,
    thumbnailClassName: null,
    labelClassName: null,
    onClick: null,
};

function HybridCard({
    href,
    thumbnail,
    thumbnailRatio,
    children,
    inner,
    brand,
    isLabel,
    labelFor,
    containerRef,
    className,
    thumbnailClassName,
    labelClassName,
    onClick,
}) {
    const Element = href !== null || onClick !== null ? 'span' : 'div';

    const cardInner = (
        <>
            {thumbnail !== null ? (
                <Element
                    className={classNames([
                        styles.thumbnail,
                        {
                            [thumbnailClassName]: thumbnailClassName !== null,
                        },
                    ])}
                >
                    <div
                        className={styles.thumbnailShape}
                        style={{
                            paddingBottom:
                                thumbnailRatio !== null ? `${(1 / thumbnailRatio) * 100}%` : null,
                        }}
                    >
                        {thumbnail}
                    </div>
                </Element>
            ) : null}
            <Element
                className={classNames([
                    styles.label,
                    {
                        [labelClassName]: labelClassName !== null,
                    },
                ])}
            >
                {children}
            </Element>
            {inner}
        </>
    );

    if (isLabel) {
        return (
            <label
                className={classNames([
                    styles.container,
                    {
                        [className]: className !== null,
                    },
                ])}
                htmlFor={labelFor}
                ref={containerRef}
                data-brand={brand}
            >
                {cardInner}
            </label>
        );
    }

    if (href !== null) {
        return (
            <Link
                href={href}
                onClick={onClick}
                className={classNames([
                    styles.container,
                    {
                        [className]: className !== null,
                    },
                ])}
                ref={containerRef}
                data-brand={brand}
            >
                {cardInner}
            </Link>
        );
    }
    if (onClick !== null) {
        return (
            <BasicButton
                onClick={onClick}
                className={classNames([
                    styles.container,
                    {
                        [className]: className !== null,
                    },
                ])}
                data-brand={brand}
            >
                {cardInner}
            </BasicButton>
        );
    }
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            ref={containerRef}
            data-brand={brand}
        >
            {cardInner}
        </div>
    );
}

HybridCard.propTypes = propTypes;
HybridCard.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <HybridCard {...props} containerRef={ref} />);
