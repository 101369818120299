import { to, useSpringValue } from '@react-spring/web';
import { tr } from 'intl-tel-input/i18n';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useDrag } from 'react-use-gesture';

import useDragProgress from './useDragProgress';

const ZOOM_PROGRESS_ACTIVATION_THRESHOLD = 0.3;
const ZOOM_VELOCITY_ACTIVATION_THRESHOLD = 0.7;
const DRAG_PROGRESS_ACTIVATION_THRESHOLD = 0.2;
const DRAG_VELOCITY_ACTIVATION_THRESHOLD = 0.4;
const ZOOMEDIN_DRAG_THRESHOLD = 50;
const ZOOMEDOUT_DRAG_THRESHOLD = 20;

function useTimelineSpring({
    timeline = [],
    timelineIndex = 0,
    containerWidth = 0,
    containerHeight = 0,
    onIndexChange = null,
    onZoomIn = null,
    onZoomOut = null,
    zoomOnDrag = false,
    disabled = false,
    dragDisabled = false,
    dragWhenZoomedInDisabled = false,
    navigationDisabled = false,
    neighborActive = 1,
    neighborOffset = 3,
    neighborScale = 0.7,
    neighborOpacity = 0.5,
    zoomOutScale = 0.85,
    maxScrollY = 200,
    scrollY = 0,
    initialZoomedIn = false,
    springConfig = { tension: 300, friction: 35 },
} = {}) {
    // const { y: windowScrollY } = useWindowScroll();

    const [currentIndex, setCurrentIndex] = useState(timelineIndex);
    const [zoomedIn, setZoomedIn] = useState(initialZoomedIn);
    const [zoomingIn, setZoomingIn] = useState(false);
    const [zoomingOut, setZoomingOut] = useState(false);
    const [direction, setDirection] = useState(0);
    // const [scrolled, setScrolled] = useState(false);

    const zoomInSpring = useSpringValue(initialZoomedIn ? 1 : 0, {
        onStart: ({ value }) => {
            setZoomingOut(value.goal === 0);
            setZoomingIn(value.goal === 1);
        },
        onRest: ({ value }) => {
            setZoomingOut(false);
            setZoomingIn(false);
            if (value === 1) {
                setZoomedIn(true);
                if (onZoomIn !== null) {
                    onZoomIn();
                }
            } else if (value === 0) {
                setZoomedIn(false);
                if (onZoomOut !== null) {
                    onZoomOut();
                }
            }
        },
        config: springConfig,
    });

    const zoomIn = useCallback(() => {
        zoomInSpring.start(1);
    }, [zoomInSpring]);

    const zoomOut = useCallback(() => {
        zoomInSpring.start(0);
    }, [zoomInSpring]);

    const timelineCount = timeline.length;
    const computeTimelineProgress = useCallback(
        ({ active, movement: [mx], velocity: [vx] }) => {
            if (navigationDisabled) {
                return timelineIndex;
            }
            const p = mx / containerWidth; // drag "ratio": how much of the screen width has been swiped?
            const forwards = mx < 0; // true if swiping to left (to navigate forwards)
            const newIndex = !forwards ? timelineIndex - 1 : timelineIndex + 1; // which item index are we moving towards?
            const reachedThreshold =
                vx > DRAG_VELOCITY_ACTIVATION_THRESHOLD ||
                Math.abs(p) > DRAG_PROGRESS_ACTIVATION_THRESHOLD;
            const reachedBounds = newIndex < 0 || newIndex >= timelineCount; // have we reached the end of the stack?
            const damper = reachedBounds ? 0.1 : 1;
            const progress = Math.max(-1, Math.min(1, p * damper));

            if (!active) {
                return reachedThreshold && !reachedBounds ? newIndex : timelineIndex;
            }
            return timelineIndex - progress;
        },
        [containerWidth, timelineIndex, timelineCount, navigationDisabled],
    );

    const onProgress = useCallback(
        (progress, { active }) => {
            const delta = Math.abs(progress - timelineIndex);
            const newDirection = progress > timelineIndex ? 1 : -1;
            setDirection(newDirection);
            const reachedBounds = progress < 0 || progress >= timelineCount; // have we reached the end of the stack?
            if (delta >= 1 && !reachedBounds && onIndexChange !== null) {
                // setCurrentIndex(progress);
                onIndexChange(newDirection === -1 ? Math.floor(progress) : Math.ceil(progress));
            }
        },
        [onIndexChange, timelineIndex, timelineCount, containerHeight],
    );

    // const onTap = useCallback(({ event }) => {}, []);

    const [transitioned, setTransitioned] = useState(true);
    const onTransitionStart = useCallback(() => {
        setTransitioned(false);
    }, [setTransitioned]);

    const onTransitionComplete = useCallback(
        ({ value }) => {
            setTransitioned(true);
            if (value % 1 === 0 || value === 0) {
                setCurrentIndex(value);
                setDirection(0);
            }
        },
        [setTransitioned],
    );

    const springParams = useMemo(
        () => ({
            config: springConfig,
            onStart: onTransitionStart,
            onRest: onTransitionComplete,
        }),
        [onTransitionStart, onTransitionComplete, springConfig],
    );

    const {
        progress: progressSpring,
        dragging,
        ...drag
    } = useDragProgress({
        progress: timelineIndex,
        disabled,
        dragDisabled: dragDisabled || (dragWhenZoomedInDisabled && zoomedIn && scrollY > 0),
        threshold: zoomedIn && scrollY > 0 ? ZOOMEDIN_DRAG_THRESHOLD : ZOOMEDOUT_DRAG_THRESHOLD,
        computeProgress: computeTimelineProgress,
        onProgress,
        // onTap,
        springParams,
    });

    useEffect(() => {
        if (dragging && zoomedIn) {
            zoomOut();
        }
    }, [dragging, zoomedIn]);

    const bindSwipe = useDrag(
        ({ swipe: [, swipeY] }) => {
            if (swipeY !== -1 && swipeY !== 1) {
                return;
            }
            if (swipeY === 1) {
                zoomOut();
            } else if (swipeY === -1) {
                // zoomIn();
            }
        },
        {
            enabled: zoomOnDrag,
            axis: 'y',
            swipe: {
                velocity: [1, 1],
                distance: [75, 75],
            },
        },
    );

    const getStyleByIndex = useCallback(
        (index, customZoomOutScale = null) => ({
            opacity: to([progressSpring, zoomInSpring], (progress, zoomInValue) => {
                const t = index - progress;
                if (Math.abs(t) > neighborActive + 1) return 0;
                const clamped = Math.min(1, Math.max(0, Math.abs(t)));

                return Math.min(
                    1 - (1 - neighborOpacity) * clamped,
                    index === currentIndex || index === timelineIndex ? 1 : 1 - zoomInValue,
                );
            }),
            borderRadius: to([zoomInSpring], (zoomInValue) => {
                const finalZoomOutScale = customZoomOutScale || zoomOutScale;
                if (index === currentIndex) {
                    return finalZoomOutScale === 1 ? '0px' : `${(1 - zoomInValue) * 10}px`;
                }
                return '10px';
            }),
            transform: to([progressSpring, zoomInSpring], (progress, zoomInValue) => {
                const t = index - progress;
                if (Math.abs(t) > neighborActive + 1) return `translate(100%)`;
                const clamped = Math.min(1, Math.max(0, Math.abs(t)));

                const finalZoomOutScale =
                    index === currentIndex || index === timelineIndex
                        ? customZoomOutScale || zoomOutScale
                        : zoomOutScale;
                const currentScale =
                    index === currentIndex || index === timelineIndex
                        ? finalZoomOutScale + zoomInValue * (1 - finalZoomOutScale)
                        : finalZoomOutScale;
                const dragScale = currentScale - (finalZoomOutScale - neighborScale) * clamped;

                let zoomOutOffset = 0;
                if (index !== currentIndex && index !== timelineIndex) {
                    zoomOutOffset = t < 0 ? -(zoomInValue * 10) : zoomInValue * 10;
                }
                const translateX =
                    t * (100 + neighborOffset + (finalZoomOutScale / neighborScale - 1) * 50) +
                    zoomOutOffset;
                if (translateX === 0 && dragScale === 1) {
                    return 'none';
                }
                const translateY =
                    (currentIndex !== index &&
                        timelineIndex !== index &&
                        clamped !== 0 &&
                        clamped !== 1) ||
                    (currentIndex !== timelineIndex &&
                        currentIndex === index &&
                        clamped !== 0 &&
                        clamped !== 1)
                        ? scrollY / dragScale
                        : 0;
                return `scale(${dragScale}) translate(${translateX}%, ${translateY}px)`;
            }),
            // transformOrigin: `50% ${scrollY + 0}px`,
            transformOrigin:
                timelineIndex === index
                    ? `50% ${containerHeight > 0 ? `${scrollY + containerHeight / 2}px` : '50vh'}`
                    : '50%',
        }),
        [
            progressSpring,
            neighborOffset,
            neighborScale,
            zoomOutScale,
            timelineCount,
            timelineIndex,
            currentIndex,
            containerHeight,
            maxScrollY,
            scrollY,
        ],
    );

    const transitioning = useMemo(
        () =>
            timelineIndex !== progressSpring.get() ||
            progressSpring.isAnimating ||
            dragging ||
            !transitioned,
        [timelineIndex, progressSpring.isAnimating, dragging, transitioned],
    );

    const finalDirection = useMemo(() => {
        if (direction !== 0) {
            return direction;
        }
        if (transitioning) {
            return progressSpring.get() > timelineIndex ? 1 : -1;
        }
        return 0;
    }, [transitioning, direction]);

    return {
        transitioned,
        getStyleByIndex,
        progressSpring,
        zoomInSpring,
        zoomedIn,
        zoomingIn,
        zoomingOut,
        transitioning,
        zoomIn,
        zoomOut,
        dragging,
        direction: finalDirection,
        bindSwipe,
        ...drag,
    };
}

export default useTimelineSpring;
