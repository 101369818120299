/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */
import { useDrag } from '@use-gesture/react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import useDocument from '../../hooks/useDocument';

import UrbaniaButton from '../buttons/UrbaniaButton';
import DocumentFeaturedCard from '../cards/DocumentFeaturedCard';

import styles from '../../styles/pages/document-share-page.module.css';

const propTypes = {
    slug: PropTypes.string.isRequired,
    current: PropTypes.bool,
    entered: PropTypes.bool,
    entering: PropTypes.bool,
    leaving: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
};

const defaultProps = {
    formats: [
        {
            id: 'instagram',
            label: 'Instagram',
            width: 1080,
            height: 1350,
        },
    ],
    variations: [
        {
            id: 'clean',
            label: 'Transparent',
            cardTheme: 'over-bottom-left',
        },
    ],
    current: false,
    entered: false,
    entering: false,
    leaving: false,
    disabled: false,
    className: null,
    contentClassName: null,
};
function DocumentSharePage({
    slug,
    formats,
    variations,
    entered,
    entering,
    leaving,
    current,
    disabled,
    className,
    contentClassName,
}) {
    const {
        document: currentDocument = null,
        notFound = false,
        isFetching = false,
    } = useDocument(slug, {
        snippetOnly: true,
        keepPreviousData: false,
        enabled: !disabled,
    });

    const [format, setFormat] = useState(formats[0]);
    const { width, height } = format;
    const [imagePosition, setImagePosition] = useState(0.5);

    const bind = useDrag(
        ({ offset: [x, y] }) => {
            setImagePosition(Math.min(Math.max(0.5 + -x / 400, 0), 1));
        },
        {
            axis: 'x',
        },
    );

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.entered]: entered,
                    [styles.entering]: entering,
                    [styles.leaving]: leaving,
                },
                className,
                contentClassName,
            ])}
        >
            <UrbaniaButton className={styles.urbania} />

            <div className={styles.inner}>
                <header className={styles.header}>
                    <h1 className={styles.title}>Kit de partage</h1>
                </header>

                {variations.map(({ id, label, ...props }) => (
                    <div className={styles.variation} key={`variation-${id}`}>
                        <div
                            className={classNames([styles.frame, 'drag-disabled'])}
                            style={{ paddingBottom: `${(height / width) * 100}%` }}
                            {...bind()}
                        >
                            <DocumentFeaturedCard
                                theme="over-bottom-left"
                                {...props}
                                {...currentDocument}
                                className={styles.card}
                                pictureStyle={{
                                    objectPosition:
                                        imagePosition !== null ? `${imagePosition * 100}%` : null,
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

DocumentSharePage.propTypes = propTypes;
DocumentSharePage.defaultProps = defaultProps;

export default DocumentSharePage;
