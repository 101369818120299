/* eslint-disable react/jsx-props-no-spreading */
import { useResizeObserver } from '@folklore/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import Image from '../partials/Image';

import styles from '../../styles/blocks/image-block.module.css';
import ImageCaption from '../typography/ImageCaption';

const propTypes = {
    media: AppPropTypes.video.isRequired,
    size: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    size: 'responsive',
    className: null,
};

function ImageBlock({ media, size, caption, className }) {
    const { width, height } = media || {};
    const {
        ref: refResize,
        entry: { contentRect = {} },
    } = useResizeObserver();
    const { width: containerWidth, height: containerHeight } = contentRect || {};
    return (
        <figure
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            ref={refResize}
        >
            <Image
                media={media}
                width={containerWidth}
                height={containerHeight}
                loading="lazy"
                size={size}
                withoutSize
                className={styles.image}
                pictureClassName={styles.picture}
            />
            {caption !== null ? <ImageCaption className={styles.caption} html={caption} /> : null}
        </figure>
    );
}

ImageBlock.propTypes = propTypes;
ImageBlock.defaultProps = defaultProps;

export default ImageBlock;
