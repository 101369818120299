/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useSearch } from 'wouter';

import { usePopularDocuments } from '../../hooks/useDocuments';
import useSearchQuery from '../../hooks/useSearch';
import { usePageViewTracking } from '../../hooks/useTracking';

import UrbaniaButton from '../buttons/UrbaniaButton';
import SearchField from '../fields/SearchField';
import AuthorsList from '../lists/AuthorsList';
import DocumentsList from '../lists/DocumentsList';
import SectionTitle from '../typography/SectionTitle';

import styles from '../../styles/pages/search-page.module.css';

const propTypes = {
    current: PropTypes.bool,
    entered: PropTypes.bool,
    entering: PropTypes.bool,
    leaving: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
};

const defaultProps = {
    current: false,
    entered: false,
    entering: false,
    leaving: false,
    className: null,
    contentClassName: null,
};
function SearchPage({ entered, entering, leaving, current, className, contentClassName }) {
    const url = useUrlGenerator();
    const [, setLocation] = useLocation();
    const intl = useIntl();
    const search = useSearch();
    const { q: queryParam = null } = useMemo(() => queryString.parse(search), [search]);
    const [query, setQuery] = useState(queryParam);

    const {
        items: searchResults = null,
        isFetching: isFetchingResults,
        searchQuery,
        // isFetched = false,
        update,
    } = useSearchQuery(query, {
        count: 20
    });
    const onSubmit = useCallback(
        (e) => {
            e.preventDefault();
            update();
        },
        [update],
    );
    const onFieldChange = useCallback(
        (newValue) => {
            setQuery(newValue !== null && newValue.length > 0 ? newValue : null);
        },
        [setLocation, url],
    );

    useEffect(() => {
        if (searchQuery === null || searchQuery.length === 0) {
            setLocation(url('search'));
        } else {
            setLocation(`${url('search')}?${queryString.stringify({ q: searchQuery })}`);
        }
    }, [searchQuery]);

    useEffect(() => {
        if (queryParam !== searchQuery) {
            setQuery(queryParam);
        }
    }, [queryParam]);

    usePageViewTracking(current, {
        pageType: 'search',
    });

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.entered]: entered,
                    [styles.entering]: entering,
                    [styles.leaving]: leaving,
                },
                className,
                contentClassName,
            ])}
        >
            <UrbaniaButton className={styles.urbania} />
            <div className={styles.inner}>
                <form className={styles.form} onSubmit={onSubmit}>
                    <SectionTitle className={styles.title}>
                        <FormattedMessage
                            defaultMessage="Recherche sur le site"
                            description="Page title"
                        />
                    </SectionTitle>
                    <SearchField
                        value={query}
                        placeholder={intl.formatMessage({
                            defaultMessage: 'Recherche...',
                            description: 'Field placeholder',
                        })}
                        loading={isFetchingResults}
                        onChange={onFieldChange}
                        className={styles.field}
                    />
                </form>

                {searchResults !== null && query !== null ? (
                    searchResults.map(({ id, label, data }) => (
                        <div className={styles.results}>
                            <SectionTitle className={styles.sectionTitle}>{label}</SectionTitle>
                            {id === 'authors' ? (
                                <AuthorsList
                                    items={data}
                                    withoutLoader
                                    cardType="horizontal"
                                    className={styles.list}
                                    itemsClassName={styles.items}
                                />
                            ) : null}
                            {id === 'articles' ? (
                                <DocumentsList
                                    items={data}
                                    withoutLoader
                                    cardType="vertical"
                                    cardTheme="box-translucent"
                                    className={styles.list}
                                    itemsClassName={styles.items}
                                />
                            ) : null}
                        </div>
                    ))
                ) : (
                    <DocumentsList
                        // query={{
                        //     popular: 'week',
                        // }}
                        hook={usePopularDocuments}
                        presentation="layout"
                        layout="1_4_1_4"
                        count={10}
                        cardType="horizontal"
                        cardTheme="box-translucent"
                        className={styles.list}
                        itemsClassName={styles.items}
                    />
                )}
            </div>
        </div>
    );
}

SearchPage.propTypes = propTypes;
SearchPage.defaultProps = defaultProps;

export default SearchPage;
