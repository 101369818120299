import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import DurationTypo from '../typography/Duration';

import styles from '../../styles/partials/duration.module.css';
import { formatDuration } from '../../lib/utils';

const propTypes = {
    duration: PropTypes.number.isRequired,
    timestamp: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    timestamp: false,
    className: null,
};

function Duration({ duration, timestamp, className }) {
    let formattedDuration = duration < 60 ? `${duration} sec` : `${Math.round(duration / 60)} min`;
    if (timestamp) {
        formattedDuration = formatDuration(duration);
    }
    return (
        <DurationTypo
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            {formattedDuration}
        </DurationTypo>
    );
}

Duration.propTypes = propTypes;
Duration.defaultProps = defaultProps;

export default Duration;
