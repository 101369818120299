import { getCSRFHeaders, getJSON, postJSON } from '@folklore/fetch';
import queryString from 'query-string';

class Base {
    constructor(opts) {
        const { baseUrl = null } = opts || {};

        this.baseUrl = baseUrl;
    }

    getJSONWithSession(path, query = null, opts = null) {
        const { headers } = opts || {};
        return this.getJSON(path, query, {
            ...opts,
            credentials: 'include',
            headers: {
                ...(typeof window !== 'undefined' ? getCSRFHeaders() : null),
                ...headers,
            },
        });
    }

    postJSONWithSession(path, data = null, opts = null) {
        const { headers } = opts || {};
        return this.postJSON(path, data, {
            ...opts,
            credentials: 'include',
            headers: {
                ...(typeof window !== 'undefined' ? getCSRFHeaders() : null),
                ...headers,
            },
        });
    }

    getJSON(path, query = null, opts = null) {
        return getJSON(
            `${this.getFullUrl(path)}${query !== null ? `?${queryString.stringify(query, {
                arrayFormat: 'bracket',
                skipNull: true
            })}` : ''}`,
            opts,
        );
    }

    postJSON(path, data = null, opts = null) {
        return postJSON(this.getFullUrl(path), data, opts);
    }

    getFullUrl(path) {
        return `${this.baseUrl || ''}${path}`;
    }
}

export default Base;
