import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

const useSafeLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : () => {};

export default function useScrollRestoration({
    index: currentIndex,
    scroll,
    shouldUpdate,
    shouldReset,
    shouldRestore,
} = {}) {
    const scrollRef = useRef([]);
    const waitForRestoreRef = useRef(false);

    const getScroll = useCallback((index) => scrollRef.current[index] || null, []);

    const updateScroll = useCallback((index, newScroll) => {
        if (!waitForRestoreRef.current) {
            scrollRef.current[index] = newScroll;
        }
    }, []);

    const restoreScroll = useCallback((index) => {
        window.scrollTo(0, scrollRef.current[index] || 0);
        waitForRestoreRef.current = false;
    }, []);

    const waitForRestore = useCallback(() => {
        waitForRestoreRef.current = true;
    }, []);

    if (shouldUpdate) {
        updateScroll(currentIndex, scroll);
    } else {
        waitForRestore();
    }

    useEffect(() => {
        if (shouldReset) {
            window.scrollTo(0, 0);
        }
    }, [shouldReset]);

    useSafeLayoutEffect(() => {
        if (shouldRestore) {
            restoreScroll(currentIndex);
        }
    }, [currentIndex, shouldRestore, restoreScroll]);

    useEffect(() => {
        if (shouldRestore) {
            restoreScroll(currentIndex);
        }
    }, [currentIndex, shouldRestore, restoreScroll]);

    return {
        getScroll,
        updateScroll,
        restoreScroll,
        waitForRestore,
    };
}
