import { useCallback } from 'react';

const getElement = (el, tag, depth = 20, currentDepth = 0) => {
    if (el === null) {
        return null;
    }
    const tagName = typeof el.tagName !== 'undefined' ? el.tagName || null : null;
    if (tagName !== null && tagName.toLowerCase() === tag.toLowerCase()) {
        return el;
    }
    return currentDepth < depth ? getElement(el.parentNode || null, tag, depth, currentDepth + 1) : null;
};

const useOnClickLink = (onClick) => {
    const onClickBody = useCallback(
        (e, ...args) => {
            const element = getElement(e.target, 'a');
            if (element === null) {
                return;
            }
            const url = element.getAttribute('href');
            if (url !== null) {
                onClick(
                    {
                        url,
                        label: element.innerText,
                        external: url.match(/^https?:\/\//) !== null,
                        target: element,
                        event: e,
                    },
                    ...args,
                );
            }
        },
        [onClick],
    );
    return onClickBody;
};

export default useOnClickLink;
