/* eslint-disable react/jsx-props-no-spreading */
import { useIsVisible } from '@folklore/hooks';
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import useCollection from '../../hooks/useCollection';
import { getCollectionBackground } from '../../lib/backgrounds';

import CollectionSummary from '../summaries/CollectionSummary';
import SummaryRowBlock from './SummaryRowBlock';

import styles from '../../styles/blocks/summary-row-block.module.css';

const propTypes = {
    slug: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    query: PropTypes.object,
    loading: PropTypes.oneOf([null, 'lazy']),
    className: PropTypes.string,
    withBackground: PropTypes.bool,
};

const defaultProps = {
    query: null,
    loading: 'lazy',
    withBackground: false,
    className: null,
};

function CollectionRowBlock({ slug, query, loading, className, withBackground, ...props }) {
    const url = useUrlGenerator();
    const { ref, visible: isVisible } = useIsVisible({
        persist: true,
        disabled: loading !== 'lazy',
    });
    const { collection = null } = useCollection(slug, {
        enabled: loading !== 'lazy' || isVisible,
    });

    const { children = null, ...summary } = collection || {};

    const finalQuery = useMemo(
        () => ({
            collection: collection !== null ? collection.id : slug,
            ...query,
        }),
        [collection, query],
    );

    return (
        <SummaryRowBlock
            summary={collection !== null ? summary : null}
            url={url('collection', { slug })}
            summaryComponent={CollectionSummary}
            background={withBackground ? getCollectionBackground(collection) : null}
            query={finalQuery}
            disabled={collection === null}
            loading={loading}
            ref={ref}
            className={className}
            {...props}
        />
    );
}

CollectionRowBlock.propTypes = propTypes;
CollectionRowBlock.defaultProps = defaultProps;

export default CollectionRowBlock;
