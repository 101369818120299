/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import useBackgroundIsDark from '../../hooks/useBackgroundIsDark';
import * as AppPropTypes from '../../lib/PropTypes';

import { useTouchScreen } from '../../contexts/DeviceContext';
import CollectionsList from '../lists/CollectionsList';
import Background from '../partials/Background';

import styles from '../../styles/blocks/collections-row-block.module.css';

const propTypes = {
    title: PropTypes.node,
    header: PropTypes.node,
    footer: PropTypes.node,
    background: AppPropTypes.background,
    count: PropTypes.number,
    withShadow: PropTypes.bool,
    withBorderRadius: PropTypes.bool,
    className: PropTypes.string,
    titleClassName: PropTypes.string,
    innerClassName: PropTypes.string,
    listClassName: PropTypes.string,
    itemsClassName: PropTypes.string,
    backgroundClassName: PropTypes.string,
    containerRef: AppPropTypes.ref,
};

const defaultProps = {
    title: null,
    header: null,
    footer: null,
    background: null,
    count: 12,
    withShadow: null,
    withBorderRadius: false,
    className: null,
    titleClassName: null,
    innerClassName: null,
    listClassName: null,
    itemsClassName: null,
    backgroundClassName: null,
    containerRef: null,
};

function CollectionsRowBlock({
    title,
    header,
    footer,
    background,
    count,
    withShadow,
    withBorderRadius,
    className,
    titleClassName,
    innerClassName,
    listClassName,
    itemsClassName,
    backgroundClassName,
    containerRef,
    ...props
}) {
    const isTouchScreen = useTouchScreen();
    const finalBackground = useMemo(
        () => (isString(background) ? { color: background } : background),
        [background],
    );

    const backgroundIsDark = useBackgroundIsDark(finalBackground);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.textIsWhite]: backgroundIsDark,
                    [styles.withBorderRadius]: withBorderRadius,
                    [styles.withShadow]: withShadow,
                },
                className,
            ])}
            ref={containerRef}
        >
            <div className={classNames([styles.inner, innerClassName])}>
                {finalBackground !== null ? (
                    <Background
                        {...finalBackground}
                        className={classNames([styles.background, backgroundClassName])}
                    />
                ) : null}

                {header ||
                    (title !== null ? (
                        <div className={classNames([styles.title, titleClassName])}>{title}</div>
                    ) : null)}
                <div className={classNames([styles.list, listClassName])}>
                    <CollectionsList
                        presentation={isTouchScreen ? 'row' : 'grid'}
                        cardWidth={150}
                        itemsClassName={classNames([
                            styles.items,
                            {
                                [itemsClassName]: itemsClassName !== null,
                            },
                        ])}
                        cardClassName={styles.card}
                        minColumns={2}
                        placeholderCount={count}
                        count={count}
                        {...props}
                    />
                </div>
                {footer}
            </div>
        </div>
    );
}

CollectionsRowBlock.propTypes = propTypes;
CollectionsRowBlock.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => (
    <CollectionsRowBlock containerRef={ref} {...props} />
));
