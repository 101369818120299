/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useCollectionSurtitle } from '../../hooks/useSurtitle';
import * as AppPropTypes from '../../lib/PropTypes';
import getRatio from '../../lib/getRatio';

import CollectionImage from '../partials/CollectionImage';
import PlaceholderText from '../partials/PlaceholderText';
import Surtitle from '../partials/Surtitle';
import CardDescription from '../typography/CardDescription';
import CardSubtitle from '../typography/CardSubtitle';
import CardTitle from '../typography/CardTitle';
import VerticalCard from './VerticalCard';

import styles from '../../styles/cards/collection-vertical-card.module.css';

const propTypes = {
    type: PropTypes.string,
    slug: PropTypes.string,
    surtitle: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    brands: AppPropTypes.brands,
    itemsCount: PropTypes.number,
    childrenCount: PropTypes.number,
    issueNumber: PropTypes.number,
    image: AppPropTypes.image,
    theme: PropTypes.oneOf([null, 'label-on-top', 'suggestion']),
    size: AppPropTypes.cardSize,
    ratio: PropTypes.string,
    width: PropTypes.number,
    withBorder: PropTypes.bool,
    withDescription: PropTypes.bool,
    withoutBrandIcon: PropTypes.bool,
    placeholder: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    type: null,
    slug: null,
    surtitle: null,
    title: null,
    description: null,
    image: null,
    brands: null,
    itemsCount: null,
    childrenCount: null,
    issueNumber: null,
    ratio: null,
    theme: null,
    size: null,
    width: 240,
    withBorder: false,
    withDescription: false,
    withoutBrandIcon: false,
    placeholder: false,
    className: null,
};

function CollectionVerticalCard({
    type,
    slug,
    surtitle,
    title,
    description,
    image,
    brands,
    itemsCount,
    childrenCount,
    issueNumber,
    ratio,
    theme,
    size,
    width,
    withBorder,
    withDescription,
    withoutBrandIcon,
    placeholder,
    className,
}) {
    const url = useUrlGenerator();
    const [brand = null] = brands || [];
    const { handle: brandHandle } = brand || {};

    const labelOnTop = theme === 'label-on-top';
    const thumbnailRatio = getRatio(ratio, 1);
    const isBox = theme !== null && theme.indexOf('box') === 0;

    const finalSurtitle = useCollectionSurtitle({
        type,
        surtitle,
        itemsCount,
        childrenCount,
        issueNumber,
    });

    return (
        <VerticalCard
            href={
                slug !== null
                    ? url('collection', {
                          slug,
                      })
                    : null
            }
            thumbnail={
                <CollectionImage
                    brand={brandHandle}
                    title={title}
                    width={width}
                    height={width / thumbnailRatio}
                    media={image}
                    withoutBrandIcon={withoutBrandIcon}
                    withPlaceholder={placeholder}
                    className={styles.thumbnail}
                    withoutSize
                />
            }
            thumbnailRatio={thumbnailRatio}
            labelOnTop={labelOnTop}
            className={classNames([
                styles.container,
                {
                    [styles.withBorder]: withBorder,
                    [styles.box]: isBox,
                },
                styles[camelCase(size)],
                styles[camelCase(theme)],
                className,
            ])}
            labelClassName={styles.label}
            innerClassName={styles.inner}
        >
            <Surtitle surtitle={finalSurtitle} className={styles.surtitle} />
            <CardTitle className={styles.title}>
                {placeholder ? <PlaceholderText /> : title}
            </CardTitle>
            {withDescription ? (
                <CardDescription className={styles.description} html={description}>
                    {placeholder ? <PlaceholderText /> : null}
                </CardDescription>
            ) : null}
        </VerticalCard>
    );
}

CollectionVerticalCard.propTypes = propTypes;
CollectionVerticalCard.defaultProps = defaultProps;

export default CollectionVerticalCard;
