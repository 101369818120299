import Base from './Base';

class Submissions extends Base {
    get(query = null, page = null, count = null, opts = null) {
        return this.getJSONWithSession(
            '/submissions',
            {
                ...(page !== null ? { page } : null),
                ...(count !== null ? { count } : null),
                ...query,
            },
            opts,
        ).catch((e) => {
            if (e.status === 404 || e.status === 401) {
                return null;
            }
            throw e;
        });
    }

    findById(id, opts = null) {
        return this.getJSONWithSession(`/submissions/${id}`, null, opts).catch((e) => {
            if (e.status === 404 || e.status === 401) {
                return null;
            }
            throw e;
        });
    }

    findByDocument(document, opts = null) {
        return this.getJSONWithSession(
            '/submissions',
            {
                document,
            },
            opts,
        ).catch((e) => {
            if (e.status === 404 || e.status === 401) {
                return null;
            }
            throw e;
        });
    }

    findByUrl(url, opts = null) {
        return this.getJSONWithSession(
            '/submissions',
            {
                url,
            },
            opts,
        ).catch((e) => {
            if (e.status === 404 || e.status === 401) {
                return null;
            }
            throw e;
        });
    }

    submit(data, opts = null) {
        return this.postJSONWithSession('/submissions', data, opts);
    }
}

export default Submissions;
