import { loadGoogleGsi } from '@folklore/services';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useUser } from '@folklore/auth';
import { useIsCheckingAuth } from './AuthContext';

export const GoogleContext = React.createContext({
    clientId: null,
    sdk:
        typeof window !== 'undefined' &&
        typeof window.google !== 'undefined' &&
        typeof window.google.accounts !== 'undefined'
            ? window.google.accounts
            : null,
    token: null,
    setRequiresSdk: () => {},
});

export function useGoogleContext() {
    const context = useContext(GoogleContext);
    return context;
}

export function useGoogleSdk() {
    const { sdk = null, setRequiresSdk } = useGoogleContext();
    useEffect(() => {
        setRequiresSdk(true);
    }, [setRequiresSdk]);
    return sdk;
}

export function useGoogleToken() {
    const { token = null } = useGoogleContext();
    return token;
}

const propTypes = {
    clientId: PropTypes.string,
    children: PropTypes.node.isRequired,
};

const defaultProps = {
    clientId: null,
};

export function GoogleContextProvider({ clientId, children }) {
    const isCheckingAuth = useIsCheckingAuth();
    const user = useUser();
    const [requiresSdk, setRequiresSdk] = useState(false);
    const [sdk, setSdk] = useState(
        typeof window !== 'undefined' &&
            typeof window.google !== 'undefined' &&
            typeof window.google.accounts !== 'undefined' &&
            typeof window.google.accounts.id !== 'undefined'
            ? window.google.accounts
            : null,
    );
    const [token, setToken] = useState(null);
    useEffect(() => {
        if (sdk !== null || isCheckingAuth || (user !== null && !requiresSdk)) {
            return () => {};
        }

        let canceled = false;
        loadGoogleGsi().then((newSdk) => {
            if (!canceled) {
                setSdk(newSdk);
            }
            newSdk.id.initialize({
                client_id: clientId,
                callback: (response) => {
                    setToken(response.credential);
                },
            });
        });
        return () => {
            canceled = true;
        };
    }, [sdk, clientId, isCheckingAuth, user, requiresSdk]);

    useEffect(() => {}, []);

    const value = useMemo(
        () => ({
            clientId,
            sdk,
            token,
            setRequiresSdk,
        }),
        [clientId, sdk, token, setRequiresSdk],
    );

    return <GoogleContext.Provider value={value}>{children}</GoogleContext.Provider>;
}

GoogleContextProvider.propTypes = propTypes;
GoogleContextProvider.defaultProps = defaultProps;
