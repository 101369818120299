/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useSubscribed, useSubscription } from '../../hooks/useSubscription';
import * as AppPropTypes from '../../lib/PropTypes';

import { usePopupsContext } from '../../contexts/PopupsContext';
import { useSite } from '../../contexts/SiteContext';
import SubscriptionForm from '../forms/SubscriptionForm';
import Summary from './Summary';

import styles from '../../styles/summaries/micromag-summary.module.css';
import { useIsCheckingAuth } from '../../contexts/AuthContext';

const propTypes = {
    slug: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    subscribeTitle: PropTypes.string,
    subscribeDescription: PropTypes.string,
    image: AppPropTypes.image,
    metadata: PropTypes.shape({
        sponsors: PropTypes.arrayOf(AppPropTypes.sponsor),
    }),
    source: PropTypes.string,
    className: PropTypes.string,
    imageClassName: PropTypes.string,
    pictureClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
};

const defaultProps = {
    slug: null,
    title: 'Vous êtes abonné·e au Micromag',
    image: null,
    description: null,
    subscribeTitle: 'Abonnez-vous au Micromag',
    subscribeDescription:
        'Découvrez notre nouveau format de magazine gratuit en format numérique. Vous pouvez y consulter du contenu exclusif, nos suggestions de la semaine et même votre horoscope.',
    metadata: null,
    source: null,
    className: null,
    imageClassName: null,
    pictureClassName: null,
    buttonClassName: null,
};

function MicromagSummary({
    slug,
    title,
    description,
    subscribeTitle,
    subscribeDescription,
    image,
    source,
    metadata,
    className,
    imageClassName,
    pictureClassName,
    buttonClassName,
    ...props
}) {
    const { sponsors = null } = metadata || {};
    const isCheckingAuth = useIsCheckingAuth();
    const [sponsor = null] = sponsors || [];
    const url = useUrlGenerator();
    const { subscribed } = useSubscription({
        subscription: 'micromag',
        source: source || 'micromag_summary',
    });

    return (
        <Summary
            surtitle={subscribed ? 'Cette semaine' : null}
            title={!subscribed ? subscribeTitle || title : title || subscribeTitle}
            description={
                !subscribed
                    ? subscribeDescription || description
                    : description || subscribeDescription
            }
            sponsor={sponsor}
            image={image}
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            imageClassName={classNames([
                styles.image,
                {
                    [imageClassName]: imageClassName !== null,
                },
            ])}
            pictureClassName={classNames([
                styles.picture,
                {
                    [pictureClassName]: pictureClassName !== null,
                },
            ])}
            buttonClassName={classNames([
                styles.button,
                {
                    [buttonClassName]: buttonClassName !== null,
                },
            ])}
            buttonLabel={
                <FormattedMessage
                    defaultMessage="Consultez le dernier micromag"
                    description="Button label"
                />
            }
            withButton={subscribed}
            withLink={false}
            link={
                slug !== null
                    ? url('micromag', {
                          slug,
                      })
                    : null
            }
            {...props}
        >
            {!subscribed && !isCheckingAuth ? (
                <SubscriptionForm
                    subscription="micromag"
                    source="micromag_featured"
                    className={styles.form}
                />
            ) : null}
        </Summary>
    );
}

MicromagSummary.propTypes = propTypes;
MicromagSummary.defaultProps = defaultProps;

export default MicromagSummary;
