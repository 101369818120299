/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';

import Summary from './Summary';

import styles from '../../styles/summaries/brand-summary.module.css';

const propTypes = {
    slug: PropTypes.string,
    label: PropTypes.string,
    image: AppPropTypes.image,
    metadata: PropTypes.shape({
        sponsors: PropTypes.arrayOf(AppPropTypes.sponsor),
    }),
    className: PropTypes.string,
    imageClassName: PropTypes.string,
    pictureClassName: PropTypes.string,
};

const defaultProps = {
    slug: null,
    label: null,
    image: null,
    metadata: null,
    className: null,
    imageClassName: null,
    pictureClassName: null,
};

function BrandSummary({
    slug,
    label,
    image,
    metadata,
    className,
    imageClassName,
    pictureClassName,
    ...props
}) {
    const { sponsors = null } = metadata || {};
    const [sponsor = null] = sponsors || [];
    const url = useUrlGenerator();
    return (
        <Summary
            link={
                slug !== null
                    ? url('home', {
                          slug,
                      })
                    : null
            }
            title={label}
            sponsor={sponsor}
            image={image}
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            imageClassName={classNames([
                styles.image,
                {
                    [imageClassName]: imageClassName !== null,
                },
            ])}
            pictureClassName={classNames([
                styles.picture,
                {
                    [pictureClassName]: pictureClassName !== null,
                },
            ])}
            buttonLabel={<FormattedMessage defaultMessage="Voir plus" description="Button label" />}
            {...props}
        />
    );
}

BrandSummary.propTypes = propTypes;
BrandSummary.defaultProps = defaultProps;

export default BrandSummary;
